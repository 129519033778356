import React from "react";
// import { Link } from "react-router-dom";

export default function NotFound404patient() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <div></div>
          <h1>404</h1>
        </div>
        <h2>SMS Fail</h2>
        <p>
        Please ask the Clinic Admin to resend the registration SMS message.
        </p>
        {/* <Link to="/">home page</Link> */}
      </div>
    </div>
  );
}
