import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import { formatDate /*, dateAptConverter*/ } from "../lib/dateConverter";
import { remove_linebreaks } from "../lib/removeLineBreaks";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  useCreatePatientMutation,
  // useSmsByTokenQuery,
  // useGetQRbyTokenUuidQuery,
} from "../generated/graphql";

function PatientRegistationConfirm({ state, history } : any) {
  console.log("policyDetails", state.policyDetails);
  console.log("policyDetails");

  const location = useLocation();

  const { token } = useParams<{
    token: string;
  }>();

  // const { data } = useSmsByTokenQuery({
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "cache-first",
  //   variables: { token: token || "" },
  //   context: { passAuthHeader: true },
  // });

  // const smsToken = data?.SMSByToken?.token;
  // console.log("smsToken", smsToken, "token", token);

  // if(smsToken !== token) {
  //   alert("Invalid Token -Please ask admin to resend the sms");
  //   history.push("/404");
  //   return null;
  // }

  // const { data } = useGetQRbyTokenUuidQuery({
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "cache-first",
  //   variables: { token: token || "" },
  //   context: { passAuthHeader: true },
  // });

  // const pid = data?.getQRbyTokenUUID?.pid;
  // const appid = data?.getQRbyTokenUUID?.AppointmentId;
  // const hospitalId = data?.getQRbyTokenUUID?.hospitalId;

  // const isHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME?.toLowerCase();
  // console.log("isHospitalName", isHospitalName);
  // const { token, pid, hospitalId, appid } = useParams<{
  //   token: string;
  //   pid: string;
  //   hospitalId: string;
  //   appid: string;
  // }>();

  // const pid = 'N-100';
  // console.log(token, pid, hospitalId);
  let _setTimeout: null | NodeJS.Timeout = null;
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [createPatient, { loading }] = useCreatePatientMutation();
  useEffect(() => {
    return () => {
      if (_setTimeout) {
        clearTimeout(_setTimeout);
      }
    };
  }, [_setTimeout]);
  const submit = () => {
    let fe_patient_confirmed_any_positive_YesNo = "no";
    for (let i in state.covid19) {
      let value = state.covid19[i];
      if (value === "yes") {
        fe_patient_confirmed_any_positive_YesNo = "yes";
        break;
      }
    }
    //console.log(state.confirmInfo.signUrl);
    // console.log(state.token);
    //${state.policyDetails.ts_Appointment_Time === "" ? "" : `ts_Appointment_Time :   "${dateAptConverter(state.policyDetails.ts_Appointment_Time)}"`}
    //const token = localStorage.getItem('md_token');\

    const GP_Data = {
      GP_FirstName: state.policyDetails.GP_FirstName,
      GP_LastName: state.policyDetails.GP_LastName,
      GP_PracticeName:
        state.policyDetails.GP_PracticeName &&
        remove_linebreaks(state.policyDetails.GP_PracticeName),
      GP_Email: state.policyDetails.GP_Email,
      GP_Address: state.policyDetails.GP_Address,
      GP_Town: state.policyDetails.GP_Town,
      GP_Country: Number(state.policyDetails.GP_Country),
      GP_County: Number(state.policyDetails.GP_County),
      GP_PhoneNumber: state.policyDetails.GP_PhoneNumber,
      GP_FaxNumber: state.policyDetails.GP_FaxNumber,
      GP_Eircode: state.policyDetails.GP_Eircode,
    };
    const EMG_Data = {
      EMG_FirstName: state.policyDetails.emg_firstname,
      EMG_LastName: state.policyDetails.emg_lastname,
      NextOfKin:
        state.policyDetails.emg_firstname +
        " " +
        state.policyDetails.emg_lastname,
      NextOfKinPhoneNumber: state.policyDetails.emg_mobilephone,
    };

    console.log("EMG DATA", EMG_Data);
    console.log(
      "state.illnessDetails.symptoms" + state.illnessDetails.symptoms
    );

    let payOptions = "";
    let health_insurance_company = 0;

    if (location.pathname.includes("layaconfirm")) {
      payOptions = "laya";
      health_insurance_company = 1;
    }

    if (location.pathname.includes("ilconfirm")) {
      payOptions = "il";
      health_insurance_company = 2;
    }

    if (location.pathname.includes("spconfirm")) {
      payOptions = "sp";
      health_insurance_company = 3;
    }

    if (location.pathname.includes("acconfirm")) {
      payOptions = "ac";
      health_insurance_company = 4;
    }
    if (location.pathname.includes("fcconfirm")) {
      payOptions = "fc";
      health_insurance_company = 5;
    }

    //json to the db for questions
    const jsondata = {
      Barriers_To_Learning: {
        Language_Literacy: state.barrier2Learning.Language_Literacy,
        Visual_Impairment: state.barrier2Learning.Visual_Impairment,
        Physical: state.barrier2Learning.Physical,
        Hearing_Impairment: state.barrier2Learning.Hearing_Impairment,
        Other: state.barrier2Learning.Other,
      },
      Falls_Assessment: {
        Falls_Last_12_Months: state.risksAndFalls.falls12mt,
        Unsteady_Standing: state.risksAndFalls.unsteady,
        Visual_Imparment_Cause_Fall: state.risksAndFalls.visualImparment,
        Use_Mobility_Aids: state.risksAndFalls.mobilityAid,
        Use_Mobility_Aids_Details: state.risksAndFalls.details,
      },
      COVID: {
        Fever_or_Chills: state.covid19.feverOrChills,
        Smell: state.covid19.smell,
        Cough: state.covid19.cough,
        Shortness_of_breath: state.covid19.shortnessOfBreath,
        Flu_Like_Symptoms: state.covid19.fluLikeSymptoms,
        Exposed_to_Confirmed_Covid19_Case: state.covid19.exposedToConfirmedCovid19,
      },
    };

    console.log("state.policyDetails.stateid " + state.policyDetails.stateid);
    console.log(
      "state.policyDetails.countryid " + state.policyDetails.countryid
    );
    // console.log("data.countryid" + data.countryid);
    console.log("state.policyDetails.stateid " + state.policyDetails.stateid);
    console.log("jsondata ", jsondata);
    console.log("health_insurance_company " + health_insurance_company);
    console.log("payOptions " + payOptions);
    console.log("Gender " + state.policyDetails.gender);
    createPatient({
      variables: {
        token,
        data: {
          // AppointmentId: appid,
          payOptions,
          name: "Patient",
          // MM_Patient_Number:
            // String(pid) || state.policyDetails.MM_Patient_Number,
          // MRN: String(pid) || state.policyDetails.MM_Patient_Number,
          // Health_Membership_Number: state.policyDetails.memberNo,
          Patient_Title: state.policyDetails.title,
          Patient_Forenames: state.policyDetails.forenames,
          Patient_Surname: state.policyDetails.surname,
          DOB: state.policyDetails.dob,
          Gender: state.policyDetails.gender,
          Address: state.policyDetails.address,
          Addressline2: state.policyDetails.addressline2,
          City: state.policyDetails.city,
          Zipcode: state.policyDetails.zipcode,
          Country_Id: Number(state.policyDetails.countryid),
          State_Id: Number(state.policyDetails.stateid),
          Phone_Number: state.policyDetails.tel,
          Did_Patient_Elect_To_Be_Private_YesNo: state.policyDetails.pvtPatient,
          Did_You_Change_Health_Insurer_last12Months:
            state.policyDetails.healthInsurer,
          ts_Symptoms_First_Noticed: state.illnessDetails.symptoms
            ? state.illnessDetails.symptoms
            : formatDate(new Date()),
          ts_Doctor_First_Consulted_MedHistory: state.illnessDetails.consultDate
            ? state.illnessDetails.consultDate
            : formatDate(new Date()),
          Previously_Claimed_For_Illness_YesNo: state.illnessDetails.conditions,
          ts_Date_When_Claimed_For_This_Illness_Before: state.illnessDetails
            .conditionsDate
            ? state.illnessDetails.conditionsDate
            : formatDate(new Date()),
          Name_Of_Doctor_Fisrt_Attended_Referral: state.refDetails.name,
          /*
ts_Date_Of_Doctor_First_Attended_Referral
ts_Date_of_Accident
          */
          ts_Date_Of_Doctor_First_Attended_Referral: state.refDetails.date
            ? state.refDetails.date
            : undefined, //"1900-01-01",
          Address_Of_Doctor_First_Attended_Referral: state.refDetails.address
            ? remove_linebreaks(state.refDetails.address)
            : undefined,
          Admission_IsResult_Of_Accident_YesNo:
            state.injuryDetails.accidentResult,
          ts_Date_of_Accident: state.injuryDetails.date
            ? state.injuryDetails.date
            : undefined, //"1900-01-01",
          Where_Did_Accident_Injury_Occur: state.injuryDetails.place
            ? remove_linebreaks(state.injuryDetails.place)
            : undefined,
          How_Did_Accident_Injury_Occur: state.injuryDetails.cause
            ? remove_linebreaks(state.injuryDetails.cause)
            : undefined,
          Was_Accident_Injury_Fault_of_Another_Party_YesNo:
            state.injuryDetails.otherParty,
          Contact_Information_of_Responsible_Party: state.injuryDetails
            .nameAndAddress
            ? remove_linebreaks(state.injuryDetails.nameAndAddress)
            : undefined,
          Responsible_Party_Insurance_Company_Information: state.injuryDetails
            .companyName
            ? remove_linebreaks(state.injuryDetails.companyName)
            : undefined,
          Are_You_Claiming_Expenses_Via_Solicitor_YesNo: state.injuryDetails
            .solicitor
            ? remove_linebreaks(state.injuryDetails.solicitor)
            : undefined,
          Are_You_Claiming_Expenses_Via_PIAB_YesNo:
            state.injuryDetails.assessmentBoard,
          Name_Address_of_Solicitor_If_Applicable: state.injuryDetails
            .solicitorInformation
            ? remove_linebreaks(state.injuryDetails.solicitorInformation)
            : undefined,
          Agreed_to_Declaration_Consent: "Yes",
          Agreed_to_Dataprotection: "Yes",
          Agreed_to_MedoSync_Information_Processing: "Yes",
          ts_Date_Patient_Signature: formatDate(new Date()),
          Health_Insurance_Company: Number(health_insurance_company), // ? health_insurance_company : 0,
          Patient_Signature: state.confirmInfo.signUrl,
          ts_Date_Patient_Registered: formatDate(new Date()),
          // fever_or_Chills_YesNo: state.covid19.feverOrChills,
          // smell: state.covid19.smell,
          // cough_YesNo: state.covid19.cough,
          // shortness_of_breath_YesNo: state.covid19.shortnessOfBreath,
          // flu_like_symptoms_YesNo: state.covid19.fluLikeSymptoms,
          // exposed_to_confirmed_Covid19_case_YesNo:
          //   state.covid19.exposedToConfirmedCovid19,
          // Travel_abroad_last_two_weeks_YesNo:
          //   state.covid19.travelAbroadLastTwoWeeks,
          // Worked_In_Covid19_Healthcare_facility_abroad_YesNo:
          //   state.covid19.WorkedInCovid19HealthcareFacilityAbroad,
          // hospitalId: hospitalId ? Number(hospitalId) : 0,
          GPConsentYesNo: state.policyDetails.GPConsentYesNo,
          email: state.policyDetails.email,
          fe_patient_confirmed_any_positive_YesNo:
            fe_patient_confirmed_any_positive_YesNo,
          // only add GP data if they selected no to GPContactDetails
          ...(state.policyDetails.GPContactDetails === "no" ? GP_Data : {}),
          // ...(state.policyDetails.nextOfKinYesNo === "no" ? EMG_Data : {}),
          ...EMG_Data,
          // ...GP_Data,
          Supporting_Questions: JSON.stringify(jsondata),
          // Falls_Last_12_Months_YesNo: state.risksAndFalls.falls12mt,
          // Unsteady_Standing_YesNo: state.risksAndFalls.unsteady,
          // Visual_Imparment_Cause_Fall_YesNo:
          //   state.risksAndFalls.visualImparment,
          // Use_Mobility_Aids_YesNo: state.risksAndFalls.mobilityAid,
          // Use_Mobility_Aids_Details_If_Yes: state.risksAndFalls.details,
        },
      },
    })
      .then((res) => {
        console.log(res.data);
        console.log("Gender " + state.policyDetails.gender);
        if (res.data?.createPatient) {
          history.replace("/patient-success");
        } else if (res.errors) {
          console.log(res.errors);
          setErrorMessage(JSON.stringify(res.errors, null, 2));
          setError(true);
          _setTimeout = setTimeout(() => {
            setError(false);
          }, 3000);
          console.log(error);
        }
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(JSON.stringify(error, null, 2));
        _setTimeout = setTimeout(() => {
          setError(false);
        }, 3000);
        console.log(error);
      });
  };

  return (
    <div className="container p-4 bg-white my-5 border text-dark" id="message">
      <h2>Use the previous button if you need to modify anything!</h2>
      <p>
        {" "}
        Membership Number:{" "}
        <b>
          {state.policyDetails.memberNo ? state.policyDetails.memberNo : null}
        </b>
      </p>
      <p>
        {" "}
        Patient_Title: <b>{state.policyDetails.title}</b>
      </p>
      <p>
        {" "}
        Patient_Surname: <b>{state.policyDetails.surname}</b>
      </p>
      <p>
        {" "}
        Patient_Forenames: <b>{state.policyDetails.forenames}</b>
      </p>
      <p>
        {" "}
        Gender: <b>{state.policyDetails.gender}</b>
      </p>
      <p>
        {" "}
        DOB: <b>{state.policyDetails.dob}</b>
      </p>
      <p>
        {" "}
        Address: <b>{state.policyDetails.address}</b>
      </p>
      <p>
        {" "}
        Phone_Number: <b>{state.policyDetails.tel}</b>
      </p>
      <p>
        {" "}
        Patient Private: <b>{state.policyDetails.pvtPatient}</b>
      </p>
      <p>
        {" "}
        Symptoms First Noticed: <b>{state.illnessDetails.symptoms}</b>
      </p>
      <p>
        {" "}
        Doctor First Consulted MedHistory:{" "}
        <b>{state.illnessDetails.consultDate}</b>
      </p>
      <p>
        {" "}
        Previously Claimed For Illness: <b>{state.illnessDetails.conditions}</b>
      </p>
      <p>
        {" "}
        Date When Claimed For This Illness Before:{" "}
        <b>
          {state.illnessDetails.conditionsDate === ""
            ? ""
            : state.illnessDetails.conditionsDate}
        </b>
      </p>
      <p>
        {" "}
        Name Of Doctor First Attended Referral: <b>{state.refDetails.name}</b>
      </p>
      <p>
        {" "}
        Date Of Doctor First Attended Referral:{" "}
        <b>{state.refDetails.date === "" ? "" : state.refDetails.date}</b>
      </p>
      <p>
        {" "}
        Address Of Doctor First Attended Referral:{" "}
        <b>{state.refDetails.address}</b>
      </p>
      <p>
        {" "}
        Admission_IsResult_Of_Accident_YesNo:{" "}
        <b>{state.injuryDetails.accidentResult}</b>
      </p>
      <p>
        {" "}
        Date of Accident:{" "}
        <b>{state.injuryDetails.date === "" ? "" : state.injuryDetails.date}</b>
      </p>
      <p>
        {" "}
        Where Did Accident Injury Occur: <b>{state.injuryDetails.place}</b>
      </p>
      <p>
        {" "}
        How Did Accident Injury Occur: <b>{state.injuryDetails.cause}</b>
      </p>
      <p>
        {" "}
        Was Accident Injury Fault of Another Party:{" "}
        <b>{state.injuryDetails.otherParty}</b>
      </p>
      <p>
        {" "}
        Contact Information of Responsible Party:{" "}
        <b>{state.injuryDetails.nameAndAddress}</b>
      </p>
      <p>
        {" "}
        Responsible Party_Insurance_Company_Information:{" "}
        <b>{state.injuryDetails.companyName}</b>
      </p>
      <p>
        {" "}
        Are You Claiming Expenses Via Solicitor:{" "}
        <b>{state.injuryDetails.solicitor}</b>
      </p>
      <p>
        {" "}
        Are You Claiming Expenses Via PIAB:{" "}
        <b>{state.injuryDetails.assessmentBoard}</b>
      </p>
      <p>
        {" "}
        Name Address of Solicitor If Applicable:{" "}
        <b>{state.injuryDetails.solicitorInformation}</b>
      </p>
      <p>
        {" "}
        GPConsentYesNo: <b>{state.policyDetails.GPConsentYesNo}</b>
      </p>
      <p>
        {" "}
        GP_FirstName: <b>{state.policyDetails.GP_FirstName}</b>
      </p>
      <p>
        {" "}
        GP_LastName: <b>{state.policyDetails.GP_LastName}</b>
      </p>
      <p>
        {" "}
        GP_PracticeName: <b>{state.policyDetails.GP_PracticeName}</b>
      </p>
      <p>
        {" "}
        GP_Email: <b>{state.policyDetails.GP_Email}</b>
      </p>
      <p>
        {" "}
        GP_Address: <b>{state.policyDetails.GP_Address}</b>
      </p>
      <p>
        {" "}
        GP_Town: <b>{state.policyDetails.GP_Town}</b>
      </p>
      <p>
        {" "}
        GP_PhoneNumber: <b>{state.policyDetails.GP_PhoneNumber}</b>
      </p>

      <p>
        {" "}
        Emergency Contact Name:{" "}
        <b>
          {state.policyDetails.emg_firstname +
            " " +
            state.policyDetails.emg_lastname}
        </b>
      </p>
      <p>
        {" "}
        Emergency Contact Phone Number:{" "}
        <b>{state.policyDetails.emg_mobilephone}</b>
        <b></b>
      </p>
      <p>
        {" "}
        Email: <b>{state.policyDetails.email}</b>
      </p>
      
      {/* <p>Scheduled_Appointment_Time: <b>{dateAptConverter(state.policyDetails.ts_Appointment_Time)}</b></p> */}

      {/* <p> Hospital ID: <b>{state.injuryDetails.insuranceCompanyId}</b></p> */}
      <p>
        {" "}
        You signature verifies that you have agreed to Laya's tearms and
        conditions as outlined in Section 5. i.e. Data Protection Statement and
        Declaration and Consent.{" "}
      </p>
      <p>
        {state.confirmInfo.signUrl ? (
          <img src={state.confirmInfo.signUrl} alt="sign-img" />
        ) : (
          ""
        )}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <Icon style={{ transform: "rotate(180deg)" }}>send</Icon>
            }
            className="my-3"
            onClick={(e) => history.goBack()}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Icon>send</Icon>}
            className="my-3"
            onClick={submit}
            disabled={loading}
          >
            {loading ? "Submitting" : "Submit"}
          </Button>
        </div>
      </div>
      {error && (
        <div className="alert alert-danger">
          Page did not submit, please select the back button and make sure all
          required fields are filled in.
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger">
          <pre>{errorMessage}</pre>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};
export default connect(mapStateToProps, null)(PatientRegistationConfirm);
