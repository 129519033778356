//Reads from sms table qr.name, the route when clicking the topaz signature is on the public route.
//TODO: Check if this is a security concern ?
import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import Header from "../components/UI/Header/Header";
import { useHistory } from "react-router";

import {
  OnNewQrDocument,
  // OnNewQrSubscription,
  // QRbyoffsetQuery,
  useQRbyoffsetQuery,
  useHospitalQuery,
} from "../generated/graphql";

function QRList() {
  const [qrUrl, setQRUrl] = useState("");
  const history = useHistory();

  const { data: hospitalData } = useHospitalQuery({
    variables: {
      name: process.env.REACT_APP_USE_HOSPITAL_NAME!,
    },
  });
  // const hospitalName = hospitalData?.Hospital[0].Hospital_Name;
  // const hosptal_id = hospitalData?.Hospital[0].id;
  // const hosptalName = hospitalData?.Hospital[0].name;
  const hospitals = hospitalData?.Hospital || [];

  // console.log("hospitalName, hosptal_id ", hospitalName, ", ", hosptal_id);

  const userString = localStorage.getItem("user") || "1";
  const parsedUser = JSON.parse(userString);
  const [hospitalId, setHospitalId] = useState(parsedUser.hospitalId || "1");

  // const envHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME

  const { data, subscribeToMore } = useQRbyoffsetQuery({
    variables: {
      offset: 0,
      perPage: 5,
      QRisActive: 1,
      hospitalId: parseInt(hospitalId),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  console.log(hospitalId);
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: OnNewQrDocument,
      variables: {
        hospitalId: parseInt(hospitalId),
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        console.log(prev, subscriptionData);
        return {
          QRbyoffset: {
            __typename: prev.QRbyoffset.__typename,
            hasMore: prev.QRbyoffset.hasMore,
            sms: [
              (subscriptionData as any).data.onNewQR,
              ...prev.QRbyoffset.sms,
            ],
          },
        };
      },
    });
    return () => {
      unsubscribe();
    };
  }, [subscribeToMore, hospitalId]);

  return (
    <>
      {qrUrl ? (
        <div
          className="fixed top-0 w-full h-screen bg-gray-400"
          onClick={() => setQRUrl("")}
        >
          <div className="w-full pt-36">
            <div className="P-3 flex justify-center items-center">
              {/* {data?.QRbyoffset.sms.map((qr) => qr.id)} */}
              {qrUrl}
            </div>
            <div className="w-full flex justify-center items-center">
              <QRCode value={qrUrl} />
            </div>
          </div>
        </div>
      ) : null}

      <div className="container-fluid">
        <Header>
          <>
            {/* <button
              className="btn btn-primary btn-sm px-4"
              onClick={() => {
                history.replace({
                  pathname: "/irish-or-laya",
                });
              }}
            >
              Patient Registration
            </button> */}
            <div className="p-1"> </div>
            <button
              className="btn btn-primary btn-sm px-4"
              onClick={() => {
                history.replace({
                  pathname: "/fetch-patients",
                });
              }}
            >
              Patient Checkout
            </button>

            <div className="p-1"> </div>
            <button
              className="btn btn-primary btn-sm px-4"
              onClick={() => {
                history.replace({
                  pathname: "/admit-patients",
                });
              }}
            >
              Admit Patients
            </button>
          </>
        </Header>
        <div className="d-flex justify-content-end">
          <select
            className="form-control"
            style={{ width: "25rem" }}
            name="hospital"
            value={hospitalId}
            onChange={(e) => {
              // const envHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME;
              setHospitalId(e.target.value);
              // const n = hospitals.find((v) ==> v.name  === envHospitalName)?.Hospital_Name
            }}
            id="Select1"
          >
            {/* <option value={1}>Select Hospital</option> */}
            {hospitals?.map((v, i) => {
              return (
                <option key={i} value={v.id}>
                  {v.Hospital_Name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        Use "Send QR Code" under "Register Patient" in the practice management
        software to manually register patients. This will work for both new and
        existing patients. Existing patients will have some of their information
        pre-populated on the form as it is looked up in the database.
      </div>
      <div className="container mt-10">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        QR Serial
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 pl-5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        QR
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data?.QRbyoffset.sms.map((qr) => (
                      <tr key={qr.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10"></div>
                            <div>
                              <div className="text-sm font-medium">
                                Scan the QR code or click the patient name or
                                Topaz for manual registration.
                              </div>
                              <div className="text-sm font-medium text-gray-600">
                                <button
                                  onClick={() => window.open(`${qr.QRCodeUrl}`)}
                                >
                                  {qr.name}
                                </button>
                              </div>
                              <div className="text-sm font-medium text-gray-600">
                                <button
                                  onClick={() => window.open(`${qr.smstext}`)}
                                >
                                  Topaz signature pad
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6  py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-600 float-left">
                            {/* <img style={{width: '90px'}} alt='qr-code' src={qr.QRCodeUrl!} /> */}
                            <QRCode value={qr.QRCodeUrl!} />
                          </div>
                          <div
                            className="pl-2 mt-4 pt-2 cursor-pointer float-left"
                            onClick={() => setQRUrl(qr.QRCodeUrl!)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="gray"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                              />
                            </svg>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QRList;
