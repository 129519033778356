import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useDispatch, connect } from "react-redux";
import { useUpdateDoctorMutation } from "../generated/graphql";
import { successNotification } from "../redux/actions";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

function DoctorReCaptureConfirm({ state, history }: any) {
  const { pid } = useParams<{
    pid: string;
  }>();

  const location = useLocation();
  // const maybePid = pid || ``;
  const isSigPad = location.pathname.startsWith(`/recaptureconfirm/${pid}`);

  console.log("isSigPad ", isSigPad);

  console.log("pid ", pid);

  let _setTimeout: null | NodeJS.Timeout = null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });
  const [updateDoctor] = useUpdateDoctorMutation();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (_setTimeout) {
        clearTimeout(_setTimeout);
      }
    };
  }, [_setTimeout]);

  console.log("pid ", pid, " ", error);

  const submit = async () => {
    try {
      await updateDoctor({
        variables: {
          id: parseInt(pid),
          update: {
            ts_Sign_Date: new Date().toString(),
            User_Doctor_Consultant_Signature: state.confirmInfo.signUrl,
          },
        },
      });
      setLoading(false);
      history.replace("/patient-success");
      window.scrollTo(0, 0);
      dispatch(successNotification(true, "Signature submitted successfully"));
    } catch (err) {
      setLoading(false);
      setError({ show: true, message: "Something went wrong !" });
      _setTimeout = setTimeout(() => {
        setError({ show: false, message: "" });
      }, 3000);
    }
  };

  return (
    <div className="container p-4 bg-white my-5 border text-dark" id="message">
      <h2>Use the back button if you need to modify anything!</h2>
      <p>
        {" "}
        You signature verifies that you have agreed to Laya's tearms and
        conditions as outlined in Section 5. i.e. Data Protection Statement and
        Declaration and Consent.{" "}
      </p>
      <p>
        {state.confirmInfo.signUrl ? (
          <img src={state.confirmInfo.signUrl} alt="sign-img" />
        ) : (
          ""
        )}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <Icon style={{ transform: "rotate(180deg)" }}>send</Icon>
            }
            className="my-3"
            onClick={(e) => history.goBack()}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Icon>send</Icon>}
            className="my-3"
            onClick={submit}
            disabled={loading}
          >
            {loading ? "Submitting" : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    state: state,
  };
};
export default connect(mapStateToProps, null)(DoctorReCaptureConfirm);
