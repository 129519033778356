import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {connect, useDispatch} from "react-redux";
import {useUpdatePatientMutation} from "../generated/graphql";
import {successNotification} from "../redux/actions";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

function PatientReCaptureConfirm({ state, history } : any) {
  const { pid, token } = useParams<{
    pid: string;
    token: string;
  }>();

  const maybeToken = token || ``;

  const location = useLocation();
  const isSigPad = location.pathname.startsWith(
    `/recaptureconfirm/${pid}/${maybeToken}`
  );

  console.log("isSigPad ", isSigPad);

  console.log("pid ", pid);

  let _setTimeout: null | NodeJS.Timeout = null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });
  const [updatePatientMutation] = useUpdatePatientMutation();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (_setTimeout) {
        clearTimeout(_setTimeout);
      }
    };
  }, [_setTimeout]);

  console.log("pid ", pid, " ", error);

  const submit = async () => {
    try {
      await updatePatientMutation({
        variables: {
          id: parseInt(pid),
          token: maybeToken,
          update: {
            ts_Date_Patient_Signature: new Date().toString(),
            Patient_Signature: state.confirmInfo.signUrl,
          },
        },
      });
      setLoading(false);
      history.replace("/patient-success");
      window.scrollTo(0, 0);
      dispatch(successNotification(true, "Signature submitted successfully"));
    } catch (err) {
      setLoading(false);
      setError({ show: true, message: "Something went wrong !" });
      _setTimeout = setTimeout(() => {
        setError({ show: false, message: "" });
      }, 3000);
    }
  };

  return (
    <div className="container p-4 bg-white my-5 border text-dark" id="message">
      <h2>Use the back button if you need to modify anything!</h2>
      <p>
        {" "}
        You signature verifies that you have agreed to your insurer tearms and
        conditions as outlined in the patient registration Declaration and
        Consent sections.{" "}
      </p>
      <p>
        {state.confirmInfo.signUrl ? (
          <img src={state.confirmInfo.signUrl} alt="sign-img" />
        ) : (
          ""
        )}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <Icon style={{ transform: "rotate(180deg)" }}>send</Icon>
            }
            className="my-3"
            onClick={(e) => history.goBack()}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Icon>send</Icon>}
            className="my-3"
            onClick={submit}
            disabled={loading}
          >
            {loading ? "Submitting" : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state:any) => {
  return {
    state: state,
  };
};
export default connect(mapStateToProps, null)(PatientReCaptureConfirm);
