import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import SuccessNotfication from "./components/NotificationComponent/SuccessNotification";
import { LogoClinic, LogoMedoSync } from "./components/UI/Logo/LogoMedoSync";
import { __patient_frontend__ } from "./constants";
import { HospitalRoutes } from "./HospitalRoutes";
import NotFound404 from "./pages/404";
import { PatientRegistrationRoutes } from "./PatientRegistrationRoutes";
import { setUser } from "./redux/actions";
import { StorageService } from "./redux/StorageService";

function App() {
  const dispatch = useDispatch();
  const isActive = useSelector((store: any) => store?.notification?.isActive);
  useEffect(() => {
    const user = StorageService.getUser();
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch]);

  return (
      <React.Fragment>
        <BrowserRouter>
          {isActive && <SuccessNotfication />}
          <div>

            {__patient_frontend__ ? <LogoClinic /> : <LogoMedoSync />}
          </div>


          {process.env.NODE_ENV === "production" ? (
              <Switch>
                {" "}
                {__patient_frontend__ ? (
                    <PatientRegistrationRoutes />
                ) : (
                    <HospitalRoutes />
                )}
                <Route path="/" component={NotFound404} />
              </Switch>
          ) : (
              <Switch>
                {" "}
                {__patient_frontend__ ? (<PatientRegistrationRoutes />) : (<HospitalRoutes />)}
                <Route path="/" component={NotFound404} />
              </Switch>
          )}
        </BrowserRouter>
      </React.Fragment>
  );
}

export default App;