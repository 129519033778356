import React, { useState } from "react";
import { Link } from "react-router-dom";
import Input from "../components/UI/Input/Input";
import DCSignature from "../components/PatientRegistration/consent_signature";
import { RouteComponentProps, useLocation } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { useCreateDoctorMutation } from "../generated/graphql";
import { useSelector } from "react-redux";

const matches = window.screen.width;

const useStyles = makeStyles(() => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
  },
}));

export default function DoctorRegistration({
  history,
match:{params: {token}={}}
}: RouteComponentProps<{token?: string}>) {
  const {signUrl} = useSelector((s: any) => s.confirmInfo);
  const [success] = useState({ isSucccess: false, message: "" });
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verifyPassword: "",
    Medical_Council_Number: '',
    Clinician_Specialty: '',
    medosyncChecked: false
  });
  const [createDoctorMutation, {loading}] = useCreateDoctorMutation();
  const classes = useStyles();
  const [error, setError] = useState({ isError: false, message: "" });
  const location = useLocation();
  const isSigPad = location.pathname.startsWith("/sig");
  const onSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    if (error.isError) setError({ isError: false, message: "" });
    if (!formValues.firstName) {
      setError({ isError: true, message: "Please provide firstname" });
      return;
    }
    if (!formValues.lastName) {
      setError({ isError: true, message: "Please provide lastName" });
      return;
    }
    if (!formValues.email) {
      setError({ isError: true, message: "Please provide email" });
      return;
    }
    if (!formValues.Medical_Council_Number) {
      setError({
        isError: true,
        message: "Please provide your Medical Council Number",
      });
      return;
    }
    if (!formValues.Clinician_Specialty) {
      setError({
        isError: true,
        message: "Please provide your Clinical Specialty e.g. GP",
      });
      return;
    }
    if (formValues.password.length < 10) {
      setError({
        isError: true,
        message: "Password must contain a minimum of 10 characters",
      });
      return;
    }
    if (formValues.verifyPassword !== formValues.password) {
      setError({ isError: true, message: "Password mismatch" });
      return;
    }

    if (!formValues.medosyncChecked) {
      setError({
        isError: true,
        message: "Please provide check 'I agree' to the declaration statement",
      });
      return;
    }

    if (!signUrl && !isSigPad) {
      setError({ isError: true, message: "signature is required",  });
      return;
    }

        try {
          createDoctorMutation({
            variables: {
              token,
              name: "Doctor",
              accesslevel: "Doctor",
              User_Doctor_Consultant_Name:
                formValues.firstName + " " + formValues.lastName,
              Medical_Council_Number: formValues.Medical_Council_Number ?  parseInt(formValues.Medical_Council_Number): undefined,
              Participating_Benefit_YesNo: "Yes",
              User_Doctor_Consultant_Signature: signUrl,
              Clinician_Specialty: formValues.Clinician_Specialty,
              ts_Sign_Date: new Date(),
              hospitalId: 0,
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              email: formValues.email,
              password: formValues.password,
            },
          }).then((resp) => {
            if (resp.data?.createDoctor.error) {
          setError({ isError: true, message: resp.data.createDoctor.error});
            } else if (isSigPad) {
              history.push(`/sig/getDoctorSignature/${(resp.data?.createDoctor.doctor?.id || null)}`)
            } else {
              history.push(`/consultant-registered`)
            }
          });
        } catch (error) {
          setError({ isError: true, message: "Something went wrong !" });
          setTimeout(() => {
            setError({ isError: false, message: "" });
          }, 3000);
          console.log(error);
          setTimeout(() => {
            history.replace("/");
          }, 4000);
        }
      };
  return (
    <div className="container  mt-3">
      <div className="d-flex justify-content-center h-100">
        <div className="card">
          <div className="card-header">
            <h3>Doctor Registration</h3>
          </div>
          <div className="card-body">
            {success.isSucccess && (
              <div className="alert alert-success" role="alert">
                {success.message}
              </div>
            )}
            {error.isError && (
              <div className="alert alert-danger" role="alert">
                {error.message}
              </div>
            )}
            <form onSubmit={onSubmit}>
              <span style={{ color: "#FFFAFA" }}>First Name:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-user" />
                  </span>
                </div>

                <Input
                  type="text"
                  name="firstname"
                  value={formValues.firstName}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      firstName: event.target.value,
                    })
                  }
                />
              </div>
              <span style={{ color: "#FFFAFA" }}>Last Name:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-user" />
                  </span>
                </div>
                <Input
                  type="text"
                  name="lastname"
                  value={formValues.lastName}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      lastName: event.target.value,
                    })
                  }
                />
              </div>
              <span style={{ color: "#FFFAFA" }}>Medical Council Number:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-user" />
                  </span>
                </div>
                <Input
                  type="number"
                  name="Medical_Council_Number"
                  value={formValues.Medical_Council_Number}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      Medical_Council_Number: event.target.value,
                    })
                  }
                />
              </div>
              <span style={{ color: "#FFFAFA" }}>Clinician Specialty:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-user" />
                  </span>
                </div>
                <Input
                  type="text"
                  name="Clinician_Specialty"
                  placeholder="e.g. GP"
                  value={formValues.Clinician_Specialty}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      Clinician_Specialty: event.target.value,
                    })
                  }
                />
              </div>
              <span style={{ color: "#FFFAFA" }}>Email Address:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-envelope" />
                  </span>
                </div>
                <Input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      email: event.target.value,
                    })
                  }
                />
              </div>
              <span style={{ color: "#FFFAFA" }}>Password:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-key" />
                  </span>
                </div>
                <Input
                  type="password"
                  name="password"
                  value={formValues.password}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      password: event.target.value,
                    })
                  }
                />
              </div>
              <span style={{ color: "#FFFAFA" }}>Comnfirm Password:</span>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    style={{ backgroundColor: "#42b0f5" }}
                  >
                    <i className="fas fa-key" />
                  </span>
                </div>
                <Input
                  type="password"
                  name="verify-password"
                  value={formValues.verifyPassword}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      verifyPassword: event.target.value,
                    })
                  }
                />
              </div>

              <div className={classes.sectionHead}>
                <h6 className="p-2">Consultant Declaration</h6>
              </div>
              <div
                className={`${classes.fieldContainer} ${
                  matches ? "d-flex flex-column" : "d-flex"
                }`}
              >
                <div className="my-3">
                  <p>
                    I hereby declare that the treatment I am claiming for was
                    medically necessary, personally provided by myself and the
                    entire length of stay was due to the medical condition
                    indicated on this form. If applicable - I confirm that my
                    contract of employment with the HSE / employing authority
                    entities me to charge for my professional services.
                  </p>
                  <Checkbox
                    checked={formValues.medosyncChecked}
                    color="primary"
                    required={true}
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        medosyncChecked: event.target.checked,
                      })
                    }
                  />
                  I Agree
                </div>
              </div>

              <div className="my-3">
                {isSigPad ? null : (
                  <>
                    <DCSignature  />
                    {(error.isError) ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "red",
                        }}
                      >
                        { (error.isError)  }
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  value="Register"
                  className="btn float-right login_btn"
                  disabled={loading}
                />
              </div>
            </form>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center links">
              Already have an account?
              <Link to="/" className="text-white font-weight-bold">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// }
