import { NetworkStatus } from "@apollo/client";
import moment from "moment";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import Header from "../components/UI/Header/Header";
import {
  OnNewPatientDocument,
  OnNewPatientSubscription,
  PatientsbyoffsetQuery,
  usePatientsbyoffsetQuery,
  // PatientsResponse,
} from "../generated/graphql";
import {
  convertUnixDate,
  convertUnixDate2,
  toDate,
} from "../lib/dateConverter";
// import { useOnClickSendSms } from "../lib/useOnClickSendSms";
import { __use_terminal__ } from "../settings";

const ExpandedComponent = ({ data }: any) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
);
const isAffidea = process.env.REACT_APP_USE_HOSPITAL_NAME === 'affidea'
const AdmitPatients: React.FC = () => {
  const conditionalCellStyles = (column: any) => {
    return [
      {
        when: (row: any) => typeof row[column] === "boolean" && row[column],
        style: {
          backgroundColor: "rgba(63, 195, 128, 0.9)",
          color: "white",
        },
      },
      {
        when: (row: any) => typeof row[column] === "boolean" && !row[column],
        style: {
          backgroundColor: "rgba(242, 38, 19, 0.9)",
          color: "white",
        },
      },
    ];
  };

  const generateColumns = (fields: any) => {
    return fields.map((f: any) => ({
      name: f.name,
      selector: (row: any) => row[f.field],
      sortable: !!f.sortable,
      conditionalCellStyles: conditionalCellStyles(f.field),
      format: (row: any) => (f.format ? f.format(row, f.field) : row[f.field]),
      width: f.width,
    }));
  };

  // const [sendSms, , smsSentMap] = useOnClickSendSms();

  const columns = generateColumns([
    {
      field: "ts_Date_Patient_Registered",
      name: "Registration Date",
      sortable: true,
      width: "12rem",
      format: (row: any, col: any) => (
          <div className="text-wrap">{convertUnixDate2(row[col])}</div>
      ),
    },
    {
      field: "Patient_Forenames",
      name: "First Name",
      sortable: true,
      width: "8rem",
      format: (row: any, col: any) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "Patient_Surname",
      name: "Last Name",
      sortable: true,
      width: "8rem",
      format: (row: any, col: any) => <div className="text-wrap">{row[col]}</div>,
    },
    {
      field: "DOB",
      name: "DOB",
      sortable: true,
      width: "8rem",
      format: (row: any, col: any) => (
          <div className="text-wrap">{convertUnixDate(row[col])}</div>
      ),
    },
    {
      field: "Health_Insurance_Company",
      name: "Insurance_Company",
      sortable: true,
      width: "10rem",
    },
    {
      field: "id",
      name: "Admit Patient",
      format: (row: any) => {
        return (
            <div className="w-100 cst-treatment-and-doctor text-wrap">
              <button
                  disabled={false}
                  className="btn btn-primary btn-sm btn-block"
                  onClick={() =>
                      history.push({
                        pathname: `/admin-registration/${row.id}`,
                      })
                  }
              >
                Admit Patient
              </button>
            </div>
        );
      },
      width: "12rem",
    },
    // {
    //   field: "Phone_Number",
    //   name: "Phone_Number",
    //   format: (row: PatientsResponse["patients"][0], col: any) => {
    //     return (
    //         <div className="w-100 cst-treatment-and-doctor text-wrap">
    //           <button
    //               className="btn btn-outline-primary btn-sm btn-block"
    //               onClick={() => sendSms(row)}
    //               disabled={false}
    //           >
    //             {smsSentMap[row.id as any] ? "Sent" : "Send SMS"}
    //           </button>
    //         </div>
    //     );
    //   },
    //   width: "12rem",
    // },
    {
      field: "ts_SMS_Sent",
      name: "SMS Sent At",
      sortable: true,
      width: "8rem",
      format: (row: any, col: any) => (
          <div className="text-wrap">
            {row[col] ? convertUnixDate2(row[col]) : ""}
          </div>
      ),
    },
    {
      field: "Phone_Number",
      name: "Phone Number",
      sortable: true,
      width: "12rem",
    },
  ]);

  const { data, fetchMore, networkStatus, subscribeToMore } =
      usePatientsbyoffsetQuery({
        variables: {
          offset: 0,
          perPage: 20,
          isAdmited: false,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
      });
  const history = useHistory();

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (!userString) {
      console.log("no user in localStorage so not subscribing");
      return;
    }
    const parsedUser = JSON.parse(userString);
    console.log("user: ", parsedUser, parsedUser.hospitalId);
    const unsubscribe = subscribeToMore({
      document: OnNewPatientDocument,
      variables: {
        hospitalId: parsedUser.hospitalId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        const data =
            subscriptionData.data as unknown as OnNewPatientSubscription;
        if (!data) {
          return prev as any;
        }

        return {
          patientsbyoffset: {
            ...prev.patientsbyoffset,
            patients: [data.onNewPatient, ...prev.patientsbyoffset.patients],
          },
        } as PatientsbyoffsetQuery;
      },
    });
    return () => {
      unsubscribe();
    };
  }, [subscribeToMore]);

  return (
      <div className="container-fluid">
        <Header>
          <>
            <div className="p-1"> </div>
            {isAffidea ? (
                <button
                    className="btn btn-success btn-sm px-4"
                    onClick={() => {
                      history.replace({
                        pathname: "/report",
                      });
                    }}
                >
                  Monthly Report
                </button>
            ) : ( "" )}
            <div className="p-1"> </div>
            {isAffidea ? (
                <button
                    className="btn btn-success btn-sm px-4"
                    onClick={() => {
                      history.replace({
                        pathname: "/survey-report",
                      });
                    }}
                >
                  Survey Report
                </button>
            ) : ( "" )}
            <div className="p-1"> </div>
            <button
                className="btn btn-success btn-sm px-4"
                onClick={() => {
                  history.replace({
                    pathname: "/qr-list",
                  });
                }}
            >
              Patient Registration
            </button>

            <div className="p-1"> </div>
            {__use_terminal__ ? (
                <button
                    className="btn btn-secondary btn-sm mr-5"
                    onClick={() => {
                      history.replace({
                        pathname: "/get-payments",
                      });
                    }}
                >
                  Refunds
                </button>
            ) : (
                ""
            )}

            <div className="p-1"> </div>
            <button
                className="btn btn-primary btn-sm px-4"
                onClick={() => {
                  history.replace({
                    pathname: "/fetch-patients",
                  });
                }}
            >
              Patient Checkout
            </button>
          </>
        </Header>

        <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              if (networkStatus !== NetworkStatus.ready) {
                return;
              }
              fetchMore({
                variables: {
                  perPage: 10,
                  isAdmited: false,
                  offset: data?.patientsbyoffset.patients.length,
                },
                updateQuery: (pqr, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return pqr;
                  }
                  const result = fetchMoreResult as PatientsbyoffsetQuery;
                  return {
                    patientsbyoffset: {
                      __typename: "PatientsResponse",
                      hasMore: result.patientsbyoffset.hasMore,
                      patients: [
                        ...(pqr as PatientsbyoffsetQuery).patientsbyoffset.patients,
                        ...result.patientsbyoffset.patients,
                      ],
                    },
                  } as PatientsbyoffsetQuery;
                },
              });
            }}
            hasMore={data?.patientsbyoffset.hasMore}
            loader={
              <div key={0} className="text-center">
                {" "}
                <span style={{ color: "blue" }}>Loading ...</span>
              </div>
            }
        >
          <DataTable
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              title="Admit Patients"
              columns={columns}
              className="table table-responsive table-striped"
              // overflowY={true}
              striped={true}
              noDataComponent={<div>Loading or Nothing to Display!</div>}
              progressPending={false}
              progressComponent={
                <div key={0} className="text-center">
                  {" "}
                  <span style={{ color: "blue" }}>Loading ...</span>
                </div>
              }
              data={
                  data?.patientsbyoffset.patients.filter((v) => {
                    return !v?.ts_Admit_Date;
                  }) || []
              }
              conditionalRowStyles={[
                {
                  when: (row) => {
                    if (row.ts_SMS_Sent) {
                      let sentDate = moment(toDate(row.ts_SMS_Sent));
                      let regDate = moment(toDate(row.ts_Date_Patient_Registered));
                      let diffMinutes = sentDate.diff(regDate, "minutes");
                      if (diffMinutes > 14) {
                        return true;
                      }
                    }
                    return false;
                  },
                  style: { color: "#F44E3B !important" },
                },
                {
                  when: (row) => {
                    if (
                        row.fe_patient_confirmed_any_positive_YesNo?.toLowerCase() ===
                        "yes"
                    )
                      return true;
                    return false;
                  },
                  style: { backgroundColor: "yellow !important" },
                },
              ]}
              customStyles={{
                headCells: {
                  style: {
                    fontWeight: 600,
                  },
                },
              }}
          />
        </InfiniteScroll>
      </div>
  );
};

export default AdmitPatients;