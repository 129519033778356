import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
//import CodeInput from '../../components/UI/CodeInput/CodeInput';
import Input from "../../components/UI/Input/Input";

// import Input from '../../components/UI/Input/Input';
const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
  },
}));

function AccidentSection({
  date,
  place,
  cause,
  otherParty,
  nameAndAddress,
  companyName,
  solicitor,
  assessmentBoard,
  solicitorInformation,
  accidentResult,
  handleInputChange,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  // const [selectedValue, setSelectedValue] = React.useState('');
  // const [solcitorValue, setSolicitorValue] = React.useState('');
  // const [assesmentValue, setAssesmentValue] = React.useState('');

  const handleChange = (event) => {
    handleInputChange("injuryDetails", "otherParty", event.target.value);
    // setSelectedValue(event.target.value);

    if (event.target.value === "no") {
      //history.push('/patient-registration-step-3');
    }
  };

  return (
    <div>
      <div className={classes.sectionHead}>
        <h6 className="p-2">4 Accident/Injury Section</h6>
      </div>

      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex"
        }`}
      >
        <div className="p-2">
          <label>Date of accident/injury:</label>
          <Input
            type={"date"}
            value={date}
            className="form-control width80"
            required={true}
            onChange={(e) =>
              handleInputChange("injuryDetails", "date", e.target.value)
            }
          />
        </div>
      
</div>
      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex flex-column"
        }`}
      >
        <div className="p-2">
          <label htmlFor="injury-place">
            Place where accident/injury occurred?{" "}
          </label>
          <textarea
            id="injury-place"
            cols="32"
            rows="5"
            value={place}
            required={true}
            onChange={(e) =>
              handleInputChange("injuryDetails", "place", e.target.value)
            }
            className="form-control width80"
          />
        </div>

        <div className="p-2">
          <label htmlFor="injury-occur">How accident/injury occurred? </label>
          <textarea
            id="injury-occur"
            cols="32"
            rows="5"
            value={cause}
            required={true}
            onChange={(e) =>
              handleInputChange("injuryDetails", "cause", e.target.value)
            }
            className="form-control width80"
          />
        </div>
      </div>

      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex flex-column"
        }`}
      >
        <div className="p-2">
          <span>
            Was this accident/injury due to the fault of another party?
          </span>
          <div className="p-2">
            <span>
              Yes
              <Radio
                color="primary"
                name="choice-4"
                value="yes"
                required={true}
                onChange={handleChange}
                checked={otherParty === "yes"}
              />
              No
              <Radio
                color="primary"
                name="choice-4"
                value="no"
                required={true}
                onChange={handleChange}
                checked={otherParty === "no"}
              />
            </span>
          </div>
        </div>
      </div>

      {otherParty === "yes" && (
        <React.Fragment>
          <div
            className={`px-3 ${classes.fieldContainer} ${
              matches ? "d-flex flex-column" : "d-flex flex-column"
            }`}
          >
            <label htmlFor="victim-info">
              If yes provide the name & address of person, company or public
              body responsible.{" "}
            </label>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <textarea
                className="form-control width80"
                id="victim-info"
                cols="32"
                rows="5"
                value={nameAndAddress}
                required={true}
                onChange={(e) =>
                  handleInputChange(
                    "injuryDetails",
                    "nameAndAddress",
                    e.target.value
                  )
                }
              />
            </div>

            <label htmlFor="company-info">
              Please provide the name of their insurance company?{" "}
            </label>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <textarea
                className="form-control width80"
                id="company-info"
                cols="32"
                rows="5"
                value={companyName}
                required={true}
                onChange={(e) =>
                  handleInputChange(
                    "injuryDetails",
                    "companyName",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
          <div
            className={`${classes.fieldContainer} ${
              matches ? "d-flex flex-column" : "d-flex flex-column"
            }`}
          >
            <div className="p-2">
              <span>Are you claiming these expenses through a Solicitor?</span>
              <div className="p-2">
                <span>
                  Yes
                  <Radio
                    color="primary"
                    name="choice-5"
                    required={true}
                    value="yes"
                    onChange={(e) => {
                      // setSolicitorValue(e.target.value);
                      handleInputChange(
                        "injuryDetails",
                        "solicitor",
                        e.target.value
                      );
                    }}
                    checked={solicitor === "yes"}
                  />
                  No
                  <Radio
                    color="primary"
                    name="choice-5"
                    required={true}
                    value="no"
                    onChange={(e) => {
                      // setSolicitorValue(e.target.value);
                      handleInputChange(
                        "injuryDetails",
                        "solicitor",
                        e.target.value
                      );
                    }}
                    checked={solicitor === "no"}
                  />
                </span>
              </div>
            </div>
            <div className="p-2">
              <span>Or through a Personal Injuries Assesment Board?</span>
              <div className="p-2">
                <span>
                  Yes
                  <Radio
                    color="primary"
                    required={true}
                    name="choice-6"
                    value="yes"
                    onChange={(e) => {
                      // setAssesmentValue(e.target.value);
                      handleInputChange(
                        "injuryDetails",
                        "assessmentBoard",
                        e.target.value
                      );
                    }}
                    checked={assessmentBoard === "yes"}
                  />
                  No
                  <Radio
                    color="primary"
                    required={true}
                    name="choice-6"
                    value="no"
                    onChange={(e) => {
                      // setAssesmentValue(e.target.value);
                      handleInputChange(
                        "injuryDetails",
                        "assessmentBoard",
                        e.target.value
                      );
                    }}
                    checked={assessmentBoard === "no"}
                  />
                </span>
              </div>
            </div>
            <div className="p-2">
              <label htmlFor="victim-info">Name & address of solicitor.</label>
              <textarea
                required={solicitor === "yes" ? true : false}
                id="victim-info"
                cols="32"
                rows="5"
                value={solicitorInformation}
                onChange={(e) =>
                  handleInputChange(
                    "injuryDetails",
                    "solicitorInformation",
                    e.target.value
                  )
                }
                className="form-control width80"
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  date: state.injuryDetails.date,
  place: state.injuryDetails.place,
  cause: state.injuryDetails.cause,
  otherParty: state.injuryDetails.otherParty,
  nameAndAddress: state.injuryDetails.nameAndAddress,
  companyName: state.injuryDetails.companyName,
  solicitor: state.injuryDetails.solicitor,
  assessmentBoard: state.injuryDetails.assessmentBoard,
  solicitorInformation: state.injuryDetails.solicitorInformation,
  accidentResult: state.injuryDetails.accidentResult,
});
export default connect(mapStateToProps, { handleInputChange })(
  withRouter(AccidentSection)
);
