import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useParams } from "react-router";
import Input from "../UI/Input/Input";
import { CountrySelect } from "../FormFields/CountrySelect";
import { StateSelect } from "../FormFields/StateSelect";
import SearchByPID from "./SearchByPID";
import { usePatientAutofillFromUrl } from "../../hooks/usePatientAutofillFromUrl";

const dobday = [];
const dobmonth = [];
const dobyear = [];

for (let i = 1; i <= 31; i++) {
  let v = `${i}`;
  if (v.length !== 2) {
    v = "0" + v;
  }
  dobday.push({ label: v, value: v });
}

for (let i = 1; i <= 12; i++) {
  let v = `${i}`;
  if (v.length !== 2) {
    v = "0" + v;
  }
  dobmonth.push({ label: v, value: v });
}

// for (let i = 1920; i <= new Date().getFullYear(); i++) {
//   dobyear.push({ label: `${i}`, value: `${i}` });
// }

//display from this year and scroll back (year desc)
for (
  let i = new Date().getFullYear() - 1;
  i >= new Date().getFullYear() - 101;
  i--
) {
  dobyear.push({ label: `${i}`, value: `${i}` });
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing ? theme.spacing(1) : 8, // Default to 8px if theme.spacing is not available
      width: 300,
    },
  },
  sectionHead: {
    backgroundColor: "#fe0",
    color: "black",
    padding: "10px 5px",
    borderColor: "#fe0",
    borderStyle: "solid",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "white",
    borderStyle: "solid",
  },
}));

// maybe usefull fields for policyDetails
// insuranceCompanyId,
// dob,
// GPName,
// GP_PracticeName,
// NextOfKin,
// NextOfKinPhoneNumber,
// nextOfKinYesNo,
// ts_Appointment_Time,

function PolicyDetails({
  email,
  tel,
  day,
  month,
  year,
  title,
  forenames,
  surname,
  tempGender,
  pvtPatient,
  // healthInsurer,
  GPConsentYesNo,
  tempAddress,
  tempAddress2,
  tempCity,
  emg_firstname,
  emg_lastname,
  emg_mobilephone,
  tempZipcode,
  tempCountryId,
  tempStateId,

  setEmail,
  setMobilePhone,
  setDay,
  setMonth,
  setYear,
  setTitle,
  setFornames,
  setSurname,
  setGender,
  setPvtPatient,
  // setInsurer,
  setGPConsentYesNo,

  setAddress,
  setAddress2,
  setCity,
  setEmg_firstname,
  setEmg_lastname,
  setEmg_mobilephone,
  setZipcode,
  setCountryId,
  setStateId,

  isSelfPay,
  isLaya,

  setGPContactDetails,
  GPContactDetails,

  GP_FirstName,
  setGP_FirstName,
  GP_LastName,
  setGP_LastName,
  GP_PracticeName,
  setGP_PracticeName,
  GP_Email,
  setGP_Email,
  GP_Address,
  setGP_Address,
  GP_Town,
  setGP_Town,
  GP_PhoneNumber,
  setGP_PhoneNumber,

  MM_Patient_Number,
  setMM_Patient_Number,
}) {
  const { /*pid,*/ token } = useParams();
  const classes = useStyles(isLaya);
  const matches = useMediaQuery("(max-width:500px)");

  const setDOB = (date) => {
    // format ("2014-02-04")
    setYear(date.substring(0, 4));
    setMonth(date.substring(5, 7));
    setDay(date.substring(8, 10));
  };

  // fetch data from child component (searchById)
  const setPatientById = (data) => {
    if (data) {
      setEmail(data.email);
      setMobilePhone(data.mobilephone.substring(1));
      setTitle(data.Patient_Title);
      setFornames(data.Patient_Forenames);
      setSurname(data.Patient_Surname);
      setGender(data.Gender);
      setAddress(data.Address);
      setAddress2(data.addressline2);
      setCity(data.city);
      setZipcode(data.zipcode);
      setCountryId(data.countryid);
      setStateId(data.stateid);
      setEmg_firstname(data.emg_firstname);
      setEmg_lastname(data.emg_lastname);
      setEmg_mobilephone(data.emg_mobilephone);
      setDOB(data.DOB);
      setPvtPatient("yes");
    }
  };

  usePatientAutofillFromUrl(setPatientById);

  // Galway is 964
  // Cherrywood is 488

  return (
    <div>
      <div className={classes.sectionHead}>
        <h6 className="p-4">
          {isLaya
            ? `1 Policy Details`
            : `  PART 1: This part to be completed by the Patient and/or the Policy Holder`}
        </h6>
      </div>
      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex"
        }`}
      >
        <div>
          <div className="p-2" style={{ marginBottom: 15 }}>
            {/* {!pid ? (
              <label htmlFor="tel" className="p-2">
                PID (Lookup in Practice Management URL)
              </label>
            ) : null} */}

            {!token ? (
              <SearchByPID
                setPatientById={setPatientById}
                MM_Patient_Number={MM_Patient_Number}
                setMM_Patient_Number={setMM_Patient_Number}
              />
            ) : null}

            <div className="p-2"></div>
            {isSelfPay ? (
              ""
            ) : (
              <>
                {/* //   <label htmlFor="memberNo" className="p-2">
              //     Membership Number:{" "}
              //   </label>
              //   <label className="p-2" style={{ color: "red" }}>
              //     {" "}
              //     (Optional, enter for faster check-in!):{" "}
              //   </label>
              //   <Input
              //     id="memberNo"
              //     value={memberNo}
              //     type={"text"}
              //     maxLength={10}
              //     minLength={10}
              //     onChange={(e) =>
              //       handleInputChange(
              //         "policyDetails",
              //         "memberNo",
              //         e.target.value
              //       )
              //     }
              //     className="width20"
              //   /> */}
              </>
            )}
            <label htmlFor="tel" className="p-2">
              Mobile Phone Number:{" "}
            </label>
            <Input
              id="tel"
              value={tel}
              required={true}
              type={"number"}
              onChange={(e) => setMobilePhone(e.target.value)}
              className="width20"
            />
            <label htmlFor="email" className="p-2">
              Email:{" "}
            </label>
            <Input
              id="email"
              value={email}
              required={true}
              type={"email"}
              onChange={(e) => setEmail(e.target.value)}
              className="width20"
            />

            <label htmlFor="dob" className="p-2">
              Date of Birth:
            </label>
            <div>
              <TextField
                id="outlined-select-currency"
                select
                required={true}
                label="dd"
                size="small"
                value={day}
                onChange={(e) => setDay(e.target.value)}
                variant="outlined"
                helperText="Day"
              >
                {dobday.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-currency"
                select
                required={true}
                label="mm"
                size="small"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                variant="outlined"
                helperText="Month"
              >
                {dobmonth.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-currency"
                select
                required={true}
                label="yyyy"
                size="small"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                variant="outlined"
                helperText="Year"
              >
                {dobyear.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <label htmlFor="title" className="p-2">
              Title:
            </label>

            <div>
              <select
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control width80 "
              >
                <option value={"Mr."}>Mr.</option>
                <option value={"Ms."}>Ms.</option>
                <option value={"Miss."}>Miss.</option>
                <option value={"Mrs."}>Mrs.</option>
                <option value={"Dr."}>Dr.</option>
                <option value={"Prof."}>Prof.</option>
              </select>
            </div>

            <label htmlFor="forenames" className="p-2">
              Forenames:{" "}
            </label>
            <Input
              id="forenames"
              value={forenames}
              required={true}
              onChange={(e) => setFornames(e.target.value)}
              className="width20"
            />

            <label htmlFor="surname" className="p-2">
              Surname:{" "}
            </label>
            <Input
              id="surname"
              value={surname}
              required={true}
              onChange={(e) => setSurname(e.target.value)}
              className="width20"
            />

            <label htmlFor="gender" className="p-2">
              Gender:{" "}
            </label>
            <div>
              <select
                id="gender"
                name="gender"
                value={tempGender}
                onChange={(e) => setGender(e.target.value)}
                className="form-control width80 "
              >
                <option value={"N"}>Prefer not to disclose</option>
                <option value={"M"}>Male</option>
                <option value={"F"}>Female</option>
              </select>
            </div>

            <label htmlFor="address" className="p-2">
              Address 1:{" "}
            </label>
            <Input
              id="address"
              value={tempAddress}
              required={true}
              onChange={(e) => setAddress(e.target.value)}
              className="width20"
            />

            <label htmlFor="address2" className="p-2">
              Address 2:{" "}
            </label>
            <Input
              id="address2"
              value={tempAddress2}
              required={true}
              onChange={(e) => setAddress2(e.target.value)}
              className="width20"
            />

            <label htmlFor="city" className="p-2">
              City:{" "}
            </label>
            <Input
              id="city"
              value={tempCity}
              required={true}
              onChange={(e) => setCity(e.target.value)}
              className="width20"
            />

            <label htmlFor="zipcode" className="p-2">
              Eircode:{" "}
            </label>
            <Input
              id="zipcode"
              value={tempZipcode}
              required={false}
              onChange={(e) => setZipcode(e.target.value)}
              className="width20"
            />

            <label htmlFor="countryid" className="p-2">
              Country:{" "}
            </label>
            <CountrySelect
              id="countryid"
              name="countryid"
              className={"form-control width80"}
              required={false}
              defaultValue={tempCountryId}
              onChange={(e) => setCountryId(e.target.value)}
            />

            <label htmlFor="stateid" className="p-2">
              County:{" "}
            </label>
            <StateSelect
              id="stateid"
              name="stateid"
              className={"form-control width80"}
              required={false}
              defaultValue={tempStateId}
              onChange={(e) => setStateId(e.target.value)}
            />
          </div>
        </div>

        {/* <div style={{ marginBottom: 10, marginTop: 10 }}> */}
      </div>
      <div
        className={`${classes.fieldContainer}
				${GPConsentYesNo.length === 0 /*? "required-boder":""*/}
				 ${
           matches
             ? "d-flex flex-column"
             : "d-flex justify-content-between align-items-center"
         }  mt-3`}
      >
        <div className="p-2">
          <span>
            I give my permission to share my medical records with my GP. I
            understand that I can revoke this consent by contacting the clinic.
          </span>
          <div>
            <div style={{ marginBottom: 5, marginTop: 10 }}>
              <span>
                Yes
                <Radio
                  color="primary"
                  name="choice-2"
                  value={GPConsentYesNo}
                  required={true}
                  onChange={(e) => setGPConsentYesNo("yes")}
                  checked={GPConsentYesNo === "yes"}
                />
                No
                <Radio
                  color="primary"
                  name="choice-2"
                  value={GPConsentYesNo}
                  required={true}
                  onChange={(e) => setGPConsentYesNo("no")}
                  checked={GPConsentYesNo === "no"}
                />
                No GP
                <Radio
                    color="primary"
                    name="choice-2"
                    value={GPConsentYesNo}
                    required={true}
                    onChange={(e) => setGPConsentYesNo("noGP")}
                    checked={GPConsentYesNo === "noGP"}
                />
              </span>
            </div>
          </div>

          {GPConsentYesNo === "yes" && (
            <div>
              <span>
                Does the clinic have your GP details from a previous visit?
              </span>
              <div>
                <div style={{ marginBottom: 5, marginTop: 10 }}>
                  <span>
                    Yes
                    <Radio
                      color="primary"
                      name="choice-3"
                      value="yes"
                      required={true}
                      onChange={(e) => setGPContactDetails(e.target.value)}
                      checked={GPContactDetails === "yes"}
                    />
                    No
                    <Radio
                      color="primary"
                      name="choice-3"
                      value="no"
                      required={true}
                      onChange={(e) => setGPContactDetails(e.target.value)}
                      checked={GPContactDetails === "no"}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}

          {GPContactDetails === "no" && (
            <div>
              <div>
                <label className="P-2" style={{ color: "red" }}>
                  (All GP fields are optional:)
                </label>
              </div>

              <div>
                <label htmlFor="GP_FirstName" className="p-2">
                  GP First Name:{" "}
                </label>
                <Input
                  id="GP_FirstName"
                  value={GP_FirstName}
                  required={false}
                  onChange={(e) => setGP_FirstName(e.target.value)}
                  className="width20"
                />
                <label htmlFor="GP_LastName" className="p-2">
                  GP Last Name:{" "}
                </label>
                <Input
                  id="GP_LastName"
                  value={GP_LastName}
                  required={false}
                  onChange={(e) => setGP_LastName(e.target.value)}
                  className="width20"
                />

                <label htmlFor="GP_PracticeName" className="p-2">
                  GP Practice Name:{" "}
                </label>
                <Input
                  id="GP_PracticeName"
                  value={GP_PracticeName}
                  required={false}
                  onChange={(e) => setGP_PracticeName(e.target.value)}
                  className="width20"
                />

                <label htmlFor="GP_Email" className="p-2">
                  GP Email:{" "}
                </label>
                <Input
                  id="GP_Email"
                  value={GP_Email}
                  required={false}
                  onChange={(e) => setGP_Email(e.target.value)}
                  className="width20"
                />

                <label htmlFor="GP_Address" className="p-2">
                  GP Address:{" "}
                </label>
                <Input
                  id="GP_Address"
                  value={GP_Address}
                  required={false}
                  onChange={(e) => setGP_Address(e.target.value)}
                  className="width20"
                />

                <label htmlFor="GP_Town" className="p-2">
                  GP Town:{" "}
                </label>
                <Input
                  id="GP_Town"
                  value={GP_Town}
                  required={false}
                  onChange={(e) => setGP_Town(e.target.value)}
                  className="width20"
                />

                <label htmlFor="GP_PhoneNumber" className="p-2">
                  GP Phone Number:{" "}
                </label>
                <Input
                  id="GP_PhoneNumber"
                  value={GP_PhoneNumber}
                  required={false}
                  onChange={(e) => setGP_PhoneNumber(e.target.value)}
                  className="width20"
                />

                <label htmlFor="GP_Country" className="p-2">
                  GP Country:{" "}
                </label>
                <CountrySelect
                  className={"form-control width80"}
                  required={false}
                  defaultValue={tempCountryId}
                />

                <label htmlFor="GP_County" className="p-2">
                  GP County:{" "}
                </label>
                <StateSelect
                  className={"form-control width80"}
                  required={false}
                  defaultValue={tempStateId}
                />
              </div>
            </div>
          )}

          <div>
            <label htmlFor="EMG_FirstName" className="p-2">
              Emergency Contact First Name:{" "}
            </label>
            <Input
              id="EMG_FirstName"
              value={emg_firstname}
              required={true}
              onChange={(e) => setEmg_firstname(e.target.value)}
              className="width20"
            />
            <label htmlFor="EMG_LastName" className="p-2">
              Emergency Contact Last Name:{" "}
            </label>
            <Input
              id="EMG_LastName"
              value={emg_lastname}
              required={true}
              onChange={(e) => setEmg_lastname(e.target.value)}
              className="width20"
            />
            <label htmlFor="NextOfKinPhoneNumber" className="p-2">
              Emergency contact phone number:{" "}
            </label>
            <Input
              id="NextOfKinPhoneNumber"
              value={emg_mobilephone}
              required={true}
              onChange={(e) => setEmg_mobilephone(e.target.value)}
              className="width20"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyDetails;
