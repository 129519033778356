import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { handleInputChange, setKeyAction } from "../redux/actions";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import { useParams, useLocation } from "react-router";
import PolicyDetail from "../components/PatientRegistration/PolicyDetailsEdin";
// import Covid19Details from "../components/PatientRegistration/Covid19";
// import Checkbox from "@mui/material/Checkbox";
import { __prod__ } from "../constants";
import RisksAndFalls from "../components/PatientRegistration/RisksAndFallsSection";
import BarriersToLearning from "../components/PatientRegistration/BarriersToLearning";
import { useSmsByTokenQuery } from "../generated/graphql";


function PatientregistrationSelfPay({
  history,
  accidentResult,
  handleInputChange,
  setKeyAction,
  signUrl,
  medosyncChecked,
}) {
  const { token } = useParams();
  const { data } = useSmsByTokenQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "no-cache",
    variables: { token: token },
    context: { passAuthHeader: true },
  });

  //check if the smsToken exists, if not, display a message to the patient
  const smsToken = data?.SMSByToken?.length;
  // console.log("smsToken", smsToken, "token", token);

  const location = useLocation();
  const isHospitalName = process.env.REACT_APP_USE_HOSPITAL_NAME.toLowerCase();
  // const isSigPad = location.pathname.startsWith("/sig");
  const isQRSigPadAC =
    location.pathname.startsWith("/sig/QR/ac") ||
    location.pathname.startsWith("/sig/QR/4");
  const isQRSigSP =
    location.pathname.startsWith("/sig/QR/sp") ||
    location.pathname.startsWith("/sig/QR/3");
  const isAccount =
    location.pathname.startsWith("/ac") || location.pathname.startsWith("/4");
  const isSelfPay =
    location.pathname.startsWith("/sp") || location.pathname.startsWith("/3");
  const isQRAccount =
    location.pathname.startsWith("/QR/ac") ||
    location.pathname.startsWith("/QR/4");
  const isQRSelfPay =
    location.pathname.startsWith("/QR/sp") ||
    location.pathname.startsWith("/QR/3");
  // const { pid, hospitalId, token, appid } = useParams();

  //lifting state up
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [tel, setMobilePhone] = useState("");
  // date picker
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [title, setTitle] = useState("");
  const [forenames, setFornames] = useState("");
  const [surname, setSurname] = useState("");
  const [tempGender, setGender] = useState("");

  const [pvtPatient, setPvtPatient] = useState("");
  const [GPConsentYesNo, setGPConsentYesNo] = useState("");

  const [tempAddress, setAddress] = useState("");
  const [tempAddress2, setAddress2] = useState("");
  const [tempCity, setCity] = useState("");

  const [emg_firstname, setEmg_firstname] = useState("");
  const [emg_lastname, setEmg_lastname] = useState("");
  const [emg_mobilephone, setEmg_mobilephone] = useState("");

  const [tempZipcode, setZipcode] = useState("");
  const [tempCountryId, setCountryId] = useState(99);
  const [tempStateId, setStateId] = useState(1484);

  const [GPContactDetails, setGPContactDetails] = useState("");

  const [GP_FirstName, setGP_FirstName] = useState("");
  const [GP_LastName, setGP_LastName] = useState("");
  const [GP_PracticeName, setGP_PracticeName] = useState("");
  const [GP_Email, setGP_Email] = useState("");
  const [GP_Address, setGP_Address] = useState("");
  const [GP_Town, setGP_Town] = useState("");
  const [GP_PhoneNumber, setGP_PhoneNumber] = useState("");

  const [MM_Patient_Number, setMM_Patient_Number] = useState(null);

  return (
    <div className="container mt-5">
       {!smsToken ? <div style={{ color: "#007bff" }}>{smsToken === 0 ? "Ask the clinic admin for a new sms registration link as this link was not used within 24 hours and expired" : "Loading..." }</div> :(
      <form
        onSubmit={(e) => {
          dispatch(
            setKeyAction("policyDetails", {
              memberNo: "",
              mrn: "",
              title: title,
              surname: surname,
              forenames: forenames,
              dob: year + "-" + month + "-" + day,
              address: tempAddress,
              addressline2: tempAddress2,
              city: tempCity,
              zipcode: tempZipcode,
              countryid: tempCountryId,
              stateid: tempStateId,
              tel: tel,
              pvtPatient: pvtPatient,
              GPConsentYesNo: GPConsentYesNo,
              email: email,
              emg_firstname: emg_firstname,
              emg_lastname: emg_lastname,
              emg_mobilephone: emg_mobilephone,
              GPContactDetails: GPContactDetails,
              GP_FirstName: GP_FirstName,
              GP_LastName: GP_LastName,
              GP_PracticeName: GP_PracticeName,
              GP_Email: GP_Email,
              GP_Address: GP_Address,
              GP_Town: GP_Town,
              GP_PhoneNumber: GP_PhoneNumber,
              MM_Patient_Number: MM_Patient_Number,
              gender: tempGender,
            })
          );

          console.log("dataaa", emg_firstname);
          e.preventDefault();

          // if (isSigPad) {
          //   history.push(`/sig/${hospitalId}`);
          // }

          if (token) {
            if (isAccount) {
              history.push(`/acconfirm/${token}`);
            } else if (isSelfPay) {
              history.push(`/spconfirm/${token}`);
            } else if (isQRAccount) {
              history.push(`/acconfirm/${token}`);
            } else if (isQRSelfPay) {
              history.push(`/spconfirm/${token}`);
            } else if (isQRSigPadAC) {
              history.push(`/sig/${token}`);
            } else if (isQRSigSP) {
              history.push(`/sig/${token}`);
            }
          }
          // else if (!token) {
          //   if (isSelfPay) {
          //     history.push(`/spconfirm/${hospitalId}`);
          //   } else if (isAccount) {
          //     history.push(`/acconfirm/${hospitalId}`);
          //   }
          // }
        }}
      >
        <div
          style={{
            marginTop: "-8px",
            backgroundColor: "white",
            color: "black",
            padding: "5px 5px",
            borderColor: "#B7E0D7",
            borderStyle: "solid",
          }}
        >
          {isHospitalName === "affidea" && (
            <div>
              <RisksAndFalls
                onHeaderClick={
                  __prod__
                    ? undefined
                    : () => {
                        // automatically fill out form to be used in dev
                        setKeyAction("covid19", {
                          // feverOrChills: "no",
                          cough: "no",
                          shortnessOfBreath: "no",
                          fluLikeSymptoms: "no",
                          exposedToConfirmedCovid19: "no",
                          travelAbroadLastTwoWeeks: "no",
                          WorkedInCovid19HealthcareFacilityAbroad: "no",
                          smell: "no",
                        });
                        setKeyAction("policyDetails", {
                          memberNo: "",
                          mrn: "",
                          title: "Mr.",
                          surname: "MedoSync",
                          forenames: "SP",
                          dob: "1950-12-12",
                          address: "test",
                          addressline2: "some street",
                          city: "test",
                          zipcode: "12345",
                          countryid: 99,
                          stateid: 1484,
                          tel: "0857582281",
                          pvtPatient: "yes",
                          insuranceCompanyId: "",
                          GPConsentYesNo: "yes",
                          ts_Appointment_Time: "",
                          email: "test@test.com",
                          GP_PracticeName: "",
                          EMG_FirstName: "Jackie",
                          EMG_LastName: "Nulan",
                          NextOfKinPhoneNumber: "0881234567",
                          GPContactDetails: "yes",
                        });
                        setKeyAction("illnessDetails", {
                          symptoms: "",
                          consultDate: "",
                          conditions: "no",
                          conditionsDate: "",
                        });
                        setKeyAction("injuryDetails", {
                          accidentResult: "no",
                          date: "",
                          place: "",
                          cause: "",
                          otherParty: "",
                          nameAndAddress: "",
                          companyName: "",
                          solicitor: "",
                          assessmentBoard: "",
                          solicitorInformation: "",
                          PIAB_Contact_Name: "",
                        });
                        setKeyAction("confirmInfo", {
                          signUrl: "",
                          medosyncChecked: "yes",
                        });
                      }
                }
              />
            </div>
          )}
          {/* {isHospitalName === "affidea" && (
            <div className="my-3">
              <RisksAndFalls />
            </div>
          )} */}
          {isHospitalName === "affidea" && (
            <div className="my-3">
              <BarriersToLearning />
            </div>
          )}

          <div className="my-3">
            <PolicyDetail
              email={email}
              tel={tel}
              day={day}
              month={month}
              year={year}
              title={title}
              forenames={forenames}
              surname={surname}
              tempGender={tempGender}
              pvtPatient={pvtPatient}
              GPConsentYesNo={GPConsentYesNo}
              tempAddress={tempAddress}
              tempAddress2={tempAddress2}
              tempCity={tempCity}
              emg_firstname={emg_firstname}
              emg_lastname={emg_lastname}
              emg_mobilephone={emg_mobilephone}
              tempZipcode={tempZipcode}
              tempCountryId={tempCountryId}
              tempStateId={tempStateId}
              setEmail={setEmail}
              setMobilePhone={setMobilePhone}
              setDay={setDay}
              setMonth={setMonth}
              setYear={setYear}
              setTitle={setTitle}
              setFornames={setFornames}
              setSurname={setSurname}
              setGender={setGender}
              setPvtPatient={setPvtPatient}
              setGPConsentYesNo={setGPConsentYesNo}
              setAddress={setAddress}
              setAddress2={setAddress2}
              setCity={setCity}
              setEmg_firstname={setEmg_firstname}
              setEmg_lastname={setEmg_lastname}
              setEmg_mobilephone={setEmg_mobilephone}
              setZipcode={setZipcode}
              setCountryId={setCountryId}
              setStateId={setStateId}
              isSelfPay={true}
              isLaya={false}
              setGPContactDetails={setGPContactDetails}
              GPContactDetails={GPContactDetails}
              GP_FirstName={GP_FirstName}
              setGP_FirstName={setGP_FirstName}
              GP_LastName={GP_LastName}
              setGP_LastName={setGP_LastName}
              GP_PracticeName={GP_PracticeName}
              setGP_PracticeName={setGP_PracticeName}
              GP_Email={GP_Email}
              setGP_Email={setGP_Email}
              GP_Address={GP_Address}
              setGP_Address={setGP_Address}
              GP_Town={GP_Town}
              setGP_Town={setGP_Town}
              GP_PhoneNumber={GP_PhoneNumber}
              setGP_PhoneNumber={setGP_PhoneNumber}
              MM_Patient_Number={MM_Patient_Number}
              setMM_Patient_Number={setMM_Patient_Number}
            />
          </div>

          {/* old
          <div className="my-3">
            <PolicyDetail isLaya={false} isSelfPay={true} />
          </div>
          */}

{isHospitalName === "mymedical" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of MyMedical and
                    submitting the claim on behalf of MyMedical.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    MyMedial Ltd. Privacy Policy can be found on display in the
                    Clinic.
                  </p>
                </div>
              )}
              {isHospitalName === "affidea" && (
                <div className="my-3">
                  <p>
                    Medosync Ltd. is a data processor of Affidea and submitting
                    the claim on behalf of Affidea.
                  </p>
                  <p>
                    MedoSync Ltd.{" "}
                    <a href="https://www.medosync.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    Affidea Ltd.{" "}
                    <a href="https://www.affidea.ie/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </p>
                </div>
              )}

          <div className="my-3">
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ textAlignLast: "end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<Icon>send</Icon>}
                  className="my-3"
                  // type="button"
                  type={"submit"}
                  // onClick={() => props.history.push(`/layaconfirm/${pid}/${token}`)}
                >
                  Next
                </Button>
              </div>
            </span>
          </div>
        </div>
      </form>)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  policyDetails: state.policyDetails,
  refDetails: state.refDetails,
  referralresult: state.illnessDetails.referralresult,
  ...state.confirmInfo,
});

export default connect(mapStateToProps, { handleInputChange, setKeyAction })(
  PatientregistrationSelfPay
);
