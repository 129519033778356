import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Input from "../../components/UI/Input/Input";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        marginTop: "-8px",
        backgroundColor: "white",
        color: "black",
        padding: "10px 5px",
        borderColor: "#42d7f5",
        borderStyle: "solid",
    },
}));

interface ReferralDetailsProps {
    referralDate: string;
    referralDoctor: string;
    handleInputChange: (section: string, field: string, value: string) => void;
}

const ReferralDetails: React.FC<ReferralDetailsProps> = ({
                                                             referralDate,
                                                             referralDoctor,
                                                             handleInputChange,
                                                         }) => {
    const classes = useStyles();
    const matches = useMediaQuery("(max-width:600px)");

    return (
        <div className={`${classes.fieldContainer} ${matches ? "d-flex flex-column" : "d-flex"}`}>
            <div className="p-2">
                <label>Referral Date</label>
                <Input
                    type={"date"}
                    value={referralDate}
                    required={true}
                    onChange={(e) => handleInputChange("referralDetails", "referralDate", e.target.value)}
                />
            </div>
            <div className="p-2">
                <label>Referral Doctor</label>
                <Input
                    type={"text"}
                    value={referralDoctor}
                    required={true}
                    onChange={(e) => handleInputChange("referralDetails", "referralDoctor", e.target.value)}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    referralDate: state.referralDetails?.referralDate || "",
    referralDoctor: state.referralDetails?.referralDoctor || "",
});

export default connect(mapStateToProps, { handleInputChange })(ReferralDetails);