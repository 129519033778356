import {
  HANDLE_INPUT_CHANGE_PATIENT,
  SET_USER,
  LOGOUT,
  SUCCESS_NOTIFICATION,
  SET_KEY,
  SET_TERMINAL,
} from "./types";
import { StorageService } from "./StorageService";

const initialState = {
  user: null,

  token: {
    token: "",
  },
  covid19: {
    feverOrChills: "",
    cough: "",
    shortnessOfBreath: "",
    fluLikeSymptoms: "",
    exposedToConfirmedCovid19: "",
    travelAbroadLastTwoWeeks: "",
    WorkedInCovid19HealthcareFacilityAbroad: "",
    smell: "",
  },
  policyDetails: {
    MM_Patient_Number: "",
    memberNo: "",
    mrn: "",
    title: "Mr.",
    surname: "",
    forenames: "",
    dob: "",
    address: "",
    addressline2: "",
    city: "",
    zipcode: "",
    countryid: 99,
    stateid: 1484,
    tel: "",
    pvtPatient: "",
    healthInsurer: "",
    insuranceCompanyId: "",
    GPConsentYesNo: "",
    ts_Appointment_Time: "",
    gender: "",
    email: "",
    GP_FirstName: "",
    GP_LastName: "",
    GP_PracticeName: "",
    GP_Email: "",
    GP_Address: "",
    GP_Town: "",
    GP_Country: 99,
    GP_County: 1484,
    GP_PhoneNumber: "",
    GP_FaxNumber: "",
    GP_Eircode: "",
    EMG_FirstName: "",
    EMG_LastName: "",
    NextOfKinPhoneNumber: "",
    nextOfKinYesNo: "",
    GPContactDetails: "",
  },
  illnessDetails: {
    symptoms: "",
    consultDate: "",
    conditions: "",
    conditionsDate: "",
  },
  refDetails: {
    name: "",
    date: "",
    address: "",
  },
  injuryDetails: {
    accidentResult: "",
    date: "",
    place: "",
    cause: "",
    otherParty: "",
    nameAndAddress: "",
    companyName: "",
    solicitor: "",
    assessmentBoard: "",
    solicitorInformation: "",
    PIAB_Contact_Name: "",
  },
  risksAndFalls: {
    falls12mt: "",
    unsteady: "",
    visualImparment: "",
    mobilityAid: "",
    details: "",
  },
  confirmInfo: {
    signUrl: undefined,
    statementChecked: "",
    consentChecked: "",
    medosyncChecked: "",
  },
  barrier2Learning: {
    Language_Literacy: "",
    Visual_Impairment: "",
    Physical: "",
    Hearing_Impairment: "",
    Other: "",
  },
  notification: {
    isActive: false,
    message: "",
  },
  stripe: {
    patientId: null,
    terminalLocation: StorageService.getTerminal(),
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload.user };
    case SET_KEY:
      return { ...state, [action.payload.key]: action.payload.value };
    case HANDLE_INPUT_CHANGE_PATIENT:
      let updatedObj;
      switch (action.payload.section) {
        case "policyDetails":
          updatedObj = {
            ...state,
            policyDetails: {
              ...state.policyDetails,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "token":
          updatedObj = {
            ...state,
            token: {
              ...state.token,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "illnessDetails":
          updatedObj = {
            ...state,
            illnessDetails: {
              ...state.illnessDetails,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "refDetails":
          updatedObj = {
            ...state,
            refDetails: {
              ...state.refDetails,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "injuryDetails":
          updatedObj = {
            ...state,
            injuryDetails: {
              ...state.injuryDetails,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "risksAndFalls":
          updatedObj = {
            ...state,
            risksAndFalls: {
              ...state.risksAndFalls,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "confirmInfo":
          updatedObj = {
            ...state,
            confirmInfo: {
              ...state.confirmInfo,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "barrier2Learning":
          updatedObj = {
            ...state,
            barrier2Learning: {
              ...state.barrier2Learning,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "covid19":
          updatedObj = {
            ...state,
            covid19: {
              ...state.covid19,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        default:
          break;
      }
      return {
        ...updatedObj,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case SUCCESS_NOTIFICATION:
      let notification = { ...action.payload };
      return { ...state, notification };
    case SET_TERMINAL:
      return {
        ...state,
        stripe: { ...state.stripe, terminalLocation: action.payload.terminal },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
