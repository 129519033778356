import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#a8acae",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#a8acae",
    borderStyle: "solid",
  },
}));

function BarriersToLearning({
  Language_Literacy,
  Visual_Impairment,
  Physical,
  Hearing_Impairment,
  Other,
  handleInputChange,
  onHeaderClick,
}: any) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  // console.log(
  //   JSON.stringify({
  //     Language_Literacy: Language_Literacy,
  //     Visual_Impairment: Visual_Impairment,
  //     Physical: Physical,
  //     Hearing_Impairment: Hearing_Impairment,
  //     Other: Other,
  //   })
  // );

  return (
    <div>
      <div className={classes.sectionHead}>
        <h6 className="p-2">Barriers To Learning</h6>
      </div>
      <div
        className={`${classes.fieldContainer}  ${
          matches ? "d-flex flex-column" : "d-flex flex-column"
        }`}
      >
        <div className="p-2">
          <span> Language/Literacy </span>
          <div>
            Yes
            <Radio
              color="primary"
              name="barrier2Learning-1"
              value="yes"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Language_Literacy",
                  e.target.value
                )
              }
              checked={Language_Literacy === "yes"}
            />
            No
            <Radio
              color="primary"
              name="barrier2Learning-1"
              value="no"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Language_Literacy",
                  e.target.value
                )
              }
              checked={Language_Literacy === "no"}
            />
          </div>
        </div>

        <div className="p-2">
          <span>Visual Impairment</span>
          <div>
            Yes
            <Radio
              color="primary"
              name="barrier2Learning-2"
              value="yes"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Visual_Impairment",
                  e.target.value
                )
              }
              checked={Visual_Impairment === "yes"}
            />
            No
            <Radio
              color="primary"
              name="barrier2Learning-2"
              value="no"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Visual_Impairment",
                  e.target.value
                )
              }
              checked={Visual_Impairment === "no"}
            />
          </div>
        </div>

        <div className="p-2">
          <span>Physical</span>
          <div>
            Yes
            <Radio
              color="primary"
              name="barrier2Learning-8"
              value="yes"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Physical",
                  e.target.value
                )
              }
              checked={Physical === "yes"}
            />
            No
            <Radio
              color="primary"
              name="barrier2Learning-8"
              value="no"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Physical",
                  e.target.value
                )
              }
              checked={Physical === "no"}
            />
          </div>
        </div>

        <div className="p-2">
          <span>Hearing Impairment</span>
          <div>
            Yes
            <Radio
              color="primary"
              name="barrier2Learning-3"
              value="yes"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Hearing_Impairment",
                  e.target.value
                )
              }
              checked={Hearing_Impairment === "yes"}
            />
            No
            <Radio
              color="primary"
              name="barrier2Learning-3"
              value="no"
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "barrier2Learning",
                  "Hearing_Impairment",
                  e.target.value
                )
              }
              checked={Hearing_Impairment === "no"}
            />
          </div>
        </div>

        <div className="p-2">
          <span>Other e.g. Cultural, Emotional, Cognitive (Understanding)</span>
          <div>
            Yes
            <Radio
              color="primary"
              name="barrier2Learning-4"
              value="yes"
              required={true}
              onChange={(e) =>
                handleInputChange("barrier2Learning", "Other", e.target.value)
              }
              checked={Other === "yes"}
            />
            No
            <Radio
              color="primary"
              name="barrier2Learning-4"
              value="no"
              required={true}
              onChange={(e) =>
                handleInputChange("barrier2Learning", "Other", e.target.value)
              }
              checked={Other === "no"}
            />
          </div>
        </div>
        <div className="p-2"></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state : any ) => ({
  Language_Literacy: state.barrier2Learning.Language_Literacy,
  Visual_Impairment: state.barrier2Learning.Visual_Impairment,
  Physical: state.barrier2Learning.Physical,
  Hearing_Impairment: state.barrier2Learning.Hearing_Impairment,
  Other: state.barrier2Learning.Other,
});

export default connect(mapStateToProps, { handleInputChange })(
  BarriersToLearning
);
