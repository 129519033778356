import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
// import useMediaQuery from '@mui/material/useMediaQuery';
import Radio from "@mui/material/Radio";
//import CodeInput from '../../components/UI/CodeInput/CodeInput';
import Input from "../UI/Input/Input";

// import Input from '../../components/UI/Input/Input';
const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#82BAA8",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#B7E0D7",
    borderStyle: "solid",
  },
}));

function AccidentSectionIL({
  date,
  place,
  cause,
  otherParty,
  nameAndAddress,
  companyName,
  PIAB_Contact_Name,
  solicitorInformation,
  handleInputChange,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    handleInputChange("injuryDetails", "otherParty", event.target.value);
    // setSelectedValue(event.target.value);

    if (event.target.value === "no") {
      //history.push('/patient-registration-step-3');
    }
  };

  return (
    <div>
      <div className={classes.sectionHead}>
        <h6 className="p-2">
          This section is for completion in the case of personal injury
        </h6>
      </div>

      <div className="p-2">
        <label>Date of occurance of injury:</label>
        <Input
          type="date"
          value={date}
          className="form-control width80"
          required={true}
          onChange={(e) =>
            handleInputChange("injuryDetails", "date", e.target.value)
          }
        />
      </div>

      <div className="p-2">
        <label htmlFor="injury-place">Place of injury: </label>
        <textarea
          id="injury-place"
          cols="32"
          rows="5"
          value={place}
          required={true}
          onChange={(e) =>
            handleInputChange("injuryDetails", "place", e.target.value)
          }
          className="form-control width80"
        />
      </div>

      <div className="p-2">
        <label htmlFor="injury-occur">
          Brief description of how injury occurred:
        </label>
        <textarea
          id="injury-occur"
          cols="32"
          rows="5"
          value={cause}
          required={true}
          onChange={(e) =>
            handleInputChange("injuryDetails", "cause", e.target.value)
          }
          className="form-control width80"
        />
      </div>

      <div className={classes.sectionHead}>
        <h6 className="p-2">Third party claims.</h6>
      </div>
      <div style={{ color: "#69C0AF" }}>
        <span>
          This section is for completion where you are making a claim against a
          third party (another person, company or public body, or where another
          person was responsible for your injury.
        </span>
      </div>

      <div className="p-2">
        <span>Are you making a claim against a third party?</span>
        <div className="p-2">
          <span>
            Yes
            <Radio
              color="primary"
              name="choice-4"
              value="yes"
              required={true}
              onChange={handleChange}
              checked={otherParty === "yes"}
            />
            No
            <Radio
              color="primary"
              name="choice-4"
              value="no"
              required={true}
              onChange={handleChange}
              checked={otherParty === "no"}
            />
          </span>
        </div>
      </div>

      {otherParty === "yes" && (
        <React.Fragment>
          <div className="p-2">
            <label htmlFor="resp-info">
              Name and address of person, company or public body responsible:{" "}
            </label>

            <textarea
              className="form-control width80"
              id="resp-info"
              cols="32"
              rows="5"
              value={nameAndAddress}
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "injuryDetails",
                  "nameAndAddress",
                  e.target.value
                )
              }
            />
          </div>
          <div className="p-2">
            <label htmlFor="company-info">Name of insurance company: </label>

            <textarea
              className="form-control width80"
              id="company-info"
              cols="32"
              rows="1"
              value={companyName}
              required={true}
              onChange={(e) =>
                handleInputChange(
                  "injuryDetails",
                  "companyName",
                  e.target.value
                )
              }
            />
          </div>
          <div className="p-2">
            <label htmlFor="piab-info">PIAB contact name:</label>
            <textarea
              id="piab-info"
              cols="32"
              rows="1"
              required={true}
              value={PIAB_Contact_Name}
              onChange={(e) =>
                handleInputChange(
                  "injuryDetails",
                  "PIAB_Contact_Name",
                  e.target.value
                )
              }
              className="form-control width80"
            />
          </div>
          <div className="p-2">
            <label htmlFor="solic-info">
              Name and phone number of solicitor:
            </label>
            <textarea
              id="solic-info"
              cols="32"
              rows="2"
              required={true}
              value={solicitorInformation}
              onChange={(e) =>
                handleInputChange(
                  "injuryDetails",
                  "solicitorInformation",
                  e.target.value
                )
              }
              className="form-control width80"
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  date: state.injuryDetails.date,
  place: state.injuryDetails.place,
  cause: state.injuryDetails.cause,
  otherParty: state.injuryDetails.otherParty,
  nameAndAddress: state.injuryDetails.nameAndAddress,
  companyName: state.injuryDetails.companyName,
  solicitor: state.injuryDetails.solicitor,
  assessmentBoard: state.injuryDetails.assessmentBoard,
  solicitorInformation: state.injuryDetails.solicitorInformation,
  accidentResult: state.injuryDetails.accidentResult,
});
export default connect(mapStateToProps, { handleInputChange })(
  withRouter(AccidentSectionIL)
);
