import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
import Input from "../../components/UI/Input/Input";
const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#a8acae",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#a8acae",
    borderStyle: "solid",
  },
}));

function RisksAndFalls({
  falls12mt,
  unsteady,
  visualImparment,
  mobilityAid,
  details,
  handleInputChange,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const handleChange1 = (event) => {
    handleInputChange("risksAndFalls", "falls12mt", event.target.value);
  };
  const handleChange2 = (event) => {
    handleInputChange("risksAndFalls", "unsteady", event.target.value);
  };
  const handleChange3 = (event) => {
    handleInputChange("risksAndFalls", "visualImparment", event.target.value);
  };
  const handleChange4 = (event) => {
    handleInputChange("risksAndFalls", "mobilityAid", event.target.value);
  };
  const handleChange5 = (event) => {
    handleInputChange("risksAndFalls", "details", event.target.value);
  };
  return (
    <div>
      <div className={classes.sectionHead}>
        <h6 className="p-2">Risks and Falls Assesment</h6>
      </div>

      <div
        className={`${classes.fieldContainer} ${
          matches ? "d-flex flex-column" : "d-flex flex-column"
        }`}
      >
        <div className="p-2">
          <span>Have you had any falls in the last 12 months?</span>
          <div className="p-2">
            <span>
              Yes
              <Radio
                color="primary"
                name="choice-1"
                value="yes"
                required={true}
                onChange={handleChange1}
                checked={falls12mt === "yes"}
              />
              No
              <Radio
                color="primary"
                name="choice-1"
                value="no"
                required={true}
                onChange={handleChange1}
                checked={falls12mt === "no"}
              />
            </span>
          </div>
        </div>
        <div className="p-2">
          <span>
            Are you unsteady when standing up or transferring e.g. from bed to
            chair?
          </span>
          <div className="p-2">
            <span>
              Yes
              <Radio
                color="primary"
                name="choice-2"
                value="yes"
                required={true}
                onChange={handleChange2}
                checked={unsteady === "yes"}
              />
              No
              <Radio
                color="primary"
                name="choice-2"
                value="no"
                required={true}
                onChange={handleChange2}
                checked={unsteady === "no"}
              />
            </span>
          </div>
        </div>
        <div className="p-2">
          <span>
            Do you have any visual or other imparment which could cause you to
            fall?
          </span>
          <div className="p-2">
            <span>
              Yes
              <Radio
                color="primary"
                name="choice-3"
                value="yes"
                required={true}
                onChange={handleChange3}
                checked={visualImparment === "yes"}
              />
              No
              <Radio
                color="primary"
                name="choice-3"
                value="no"
                required={true}
                onChange={handleChange3}
                checked={visualImparment === "no"}
              />
            </span>
          </div>
        </div>
        <div className="p-2">
          <span>Do you use any mobility aids?</span>
          <div className="p-2">
            <span>
              Yes
              <Radio
                color="primary"
                name="choice-4"
                value="yes"
                required={true}
                onChange={handleChange4}
                checked={mobilityAid === "yes"}
              />
              No
              <Radio
                color="primary"
                name="choice-4"
                value="no"
                required={true}
                onChange={handleChange4}
                checked={mobilityAid === "no"}
              />
            </span>
          </div>
        </div>
        <div className="p-2">
          <label>If yes to above, please give details</label>
          <Input
            type={"text"}
            value={details}
            className="form-control width80"
            required={false}
            onChange={handleChange5}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  //injdate: state.risksAndFalls.injdate,
  falls12mt: state.risksAndFalls.falls12mt,
  unsteady: state.risksAndFalls.unsteady,
  visualImparment: state.risksAndFalls.visualImparment,
  mobilityAid: state.risksAndFalls.mobilityAid,
  details: state.risksAndFalls.details,
});
export default connect(mapStateToProps, { handleInputChange })(
  withRouter(RisksAndFalls)
);
