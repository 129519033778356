import React from "react";
import { styled } from "@mui/styles";
import SignatureCanvas from "react-signature-canvas";
import { withRouter } from "react-router-dom";
import { handleInputChange } from "../../redux/actions";
import { connect } from "react-redux";

const screenWidth = window.screen.width;
styled('div')({
  backgroundColor: "#42b0f5",
  color: "white",
});
const FieldContainer = styled('div')({
  marginTop: "-8px",
  backgroundColor: "white",
  color: "black",
  padding: "5px 5px",
});

class DCSignature extends React.Component {
  constructor(props) {
    super(props);
    this.sigCanvas = React.createRef();
    this._setTimeout = null;
    this.state = {
      statementChecked: false,
      consentChecked: false,
      medoConsentChecked: false,
      disableSubmit: true,
      signCanvasWidth: 0,
      error: false,
      errorMessage: "",
    };
    this.containerRef = null;
  }

  componentWillUnmount() {
    if (this._setTimeout) {
      clearTimeout(this._setTimeout);
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  enableDisableSubmitBtn = () => {
    if (
        this.state.statementChecked &&
        this.state.consentChecked &&
        this.state.medoConsentChecked &&
        !this.sigCanvas.current.isEmpty()
    ) {
      this.setState({ disableSubmit: false });
    } else if (
        !this.state.statementChecked ||
        !this.state.consentChecked ||
        !this.state.medoConsentChecked ||
        this.sigCanvas.current.isEmpty()
    ) {
      this.setState({ disableSubmit: true });
    }
  };

  componentDidMount() {
    this.setState({ signCanvasWidth: this.containerRef.clientWidth - 15 });
  }

  render() {
    return (
        <FieldContainer className={`${screenWidth < 600 ? "d-flex flex-column" : "d-flex flex-column"} p-2`}>
          <div className="my-2"></div>
          <div ref={(ref) => (this.containerRef = ref)}>
            <div className="p-2">
              <span> Please sign your full name in CAPITAL LETTERS </span>
              <br></br>
              <i><span style={{ color: "red" }}> * Minor signatures are not accepted for insurance purposes </span></i>
            </div>
            <div className="d-flex">
              <SignatureCanvas
                  ref={this.sigCanvas}
                  penColor="black"
                  minDistance={2}
                  onEnd={() =>
                      this.props.handleInputChange(
                          "confirmInfo",
                          "signUrl",
                          this.sigCanvas.current.toDataURL()
                      )
                  }
                  canvasProps={{
                    width: this.state.signCanvasWidth,
                    height: 200,
                    className: "sigCanvas border ",
                  }}
              />
            </div>
            <div className="p-1"></div>
            <button
                variant="contained"
                className="btn btn-secondary btn-sm px-4"
                onClick={() => {
                  this.sigCanvas.current.clear();
                  this.props.handleInputChange("confirmInfo", "signUrl", '')
                }}
            >
              Clear Signature
            </button>
          </div>
        </FieldContainer>
    );
  }
}

export default connect(null, { handleInputChange })(withRouter(DCSignature));