import React from "react";
import { connect } from "react-redux";
import { handleInputChange } from "../../redux/actions";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Input from "../../components/UI/Input/Input";
import ReferralDetails from "./ReferralDetails";
import Radio from "@mui/material/Radio";

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundColor: "#42b0f5",
    color: "white",
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    padding: "10px 5px",
    borderColor: "#42d7f5",
    borderStyle: "solid",
  },
}));

interface HistorySectionProps {
  symptoms: string;
  consultDate: string;
  handleInputChange: (section: string, field: string, value: string) => void;
  conditions: string;
}

const HistorySection: React.FC<HistorySectionProps> = ({
                                                         symptoms,
                                                         consultDate,
                                                         handleInputChange,
                                                         conditions,
                                                       }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  return (
      <div>
        <div className={classes.sectionHead}>
          <h6 className="p-4">2 History of Illness Section</h6>
        </div>

        <div
            className={`${classes.fieldContainer} ${
                matches ? "d-flex flex-column" : "d-flex"
            }`}
        >
          <div>
            <div className="p-2">
              <label>When did you/the patient first notice symptoms?</label>
              <Input
                  type={"date"}
                  value={symptoms}
                  required={true}
                  id="notice"
                  className="form-control width80"
                  onChange={(e) =>
                      handleInputChange("illnessDetails", "symptoms", e.target.value)
                  }
              />

              <div style={{ marginBottom: 10, marginTop: 20 }}> </div>
              <label className="p-2">
                When did you/the patient first consult with a doctor for this
                condition?
              </label>
              <Input
                  required={true}
                  type={"date"}
                  value={consultDate}
                  onChange={(e) =>
                      handleInputChange(
                          "illnessDetails",
                          "consultDate",
                          e.target.value
                      )
                  }
              />
              <div style={{ marginBottom: 10, marginTop: 20 }}> </div>
              <div className="p-2">
              <span>
                Have you/the patient claimed for this or related conditions
                before?
              </span>
                <div className="p-2">
                <span>
                  Yes
                  <Radio
                      color="primary"
                      name="referral-1"
                      value="yes"
                      required={true}
                      onChange={(e) =>
                          handleInputChange(
                              "illnessDetails",
                              "conditions",
                              e.target.value
                          )
                      }
                      checked={conditions === "yes"}
                  />
                  No
                  <Radio
                      color="primary"
                      name="referral-1"
                      value="no"
                      required={true}
                      onChange={(e) =>
                          handleInputChange(
                              "illnessDetails",
                              "conditions",
                              e.target.value
                          )
                      }
                      checked={conditions === "no"}
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {conditions === "yes" && (
            <div className="my-3">
              <ReferralDetails />
            </div>
        )}
      </div>
  );
};

const mapStateToProps = (state: any) => ({
  symptoms: state.illnessDetails.symptoms,
  consultDate: state.illnessDetails.consultDate,
  conditions: state.illnessDetails.conditions,
});

export default connect(mapStateToProps, { handleInputChange })(HistorySection);