import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DynamicRadioGroup from "../components/survey/DynamicRadioGroup";
import DynamicYesNoGroup from "../components/survey/DynamicYesNoGroup";
import DynamicTextFieldGroup from "../components/survey/DynamicTextFieldGroup";
// import DynamicCheckboxGroup from "../components/survey/DynamicCheckboxGroup";
import { useCreateSurveyMutation, usePatientbyidQuery, useMessageTokenQuery} from "../generated/graphql";
import {useParams} from "react-router";

export default function FormControlLabelPlacement( {history} : any ) {
    const {mmpid} = useParams<{ mmpid: string }>();
    const pid = Number(mmpid);
    console.log('mmpid:', pid);
    const {token} = useParams<{
        token: string;
    }>();

    const capitaliseFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const {data: messageTokenData, loading: messageTokenLoading } = useMessageTokenQuery({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {token: token},
        context: {passAuthHeader: true},
    });
    //
    const messageToken = messageTokenData?.MessageToken.length;
    const Health_Insurance_Company = messageTokenData?.MessageToken[0].Health_Insurance_Company || 2;
    console.log("messageToken", messageToken, "Health_Insurance_Company ", Health_Insurance_Company);

    const pidToLookup = parseInt(history.location?.state?.patient.id || "0") || pid;
    const {data: patientData} = usePatientbyidQuery({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        skip: !pidToLookup,
        variables: {id: pidToLookup},
        context: {passAuthHeader: true},
    });

    const patient = patientData?.patientbyid;
    // const firstname = patient?.Patient_Forenames

    const [createSurvey] = useCreateSurveyMutation();
    type SurveyValues = {
        Q1: string;
        Q2: string;
        Q3a: string;
        Q3b: string;
        Q3c: string;
        Q3d: string;
        Q3e: string;
        Q4: string;
        Q5a: string;
        Q5b: string;
        Q5c: string;
        Q6: string;
        Q7: string;
        Q8: string;
        [key: string]: string;
    };

    const [values, setValues] = useState<SurveyValues>({
        Q1: '',
        Q2: '',
        Q3a: '',
        Q3b: '',
        Q3c: '',
        Q3d: '',
        Q3e: '',
        Q4: '',
        Q5a: '',
        Q5b: '',
        Q5c: '',
        Q6: '',
        Q7: '',
        Q8: '',
    });

    const handleTextFieldChange = (event: any) => {
        event.persist();
        console.log(event.type);
        const {name, value} = event.target;
        setValues((prevValues : SurveyValues)  => ({
            ...prevValues,
            [name]: value, // Update the specific text input with its value
        }));
    };


    const handleRadioChange = (event: any) => {
        event.persist();
        console.log(event.type);
        const {name, value} = event.target;
        setValues((prevValues : SurveyValues) => ({
            ...prevValues,
            [name]: value, // Update the specific question with its value
        }));
    };

    const handleSubmit = async () => {
        // Improved validation for all required fields
        let requiredFields: string[];
        if (Health_Insurance_Company === 2) {
         requiredFields = ['Q1', 'Q2', 'Q3a', 'Q3b', 'Q3c', 'Q3d', 'Q3e', 'Q4', 'Q5a', 'Q5b', 'Q5c', 'Q7']; //Q6 is not required
        }
        else {
         requiredFields = ['Q1', 'Q2', 'Q3a', 'Q3b', 'Q3c', 'Q3d', 'Q3e', 'Q4', 'Q5a', 'Q5b', 'Q5c', 'Q5', 'Q7', 'Q8']; //Q6 is not required
        }
        // const missingFields = requiredFields.filter(field => values[field] === '');
        const missingFields = requiredFields.filter(field => values[field as keyof SurveyValues] === '');
        if (missingFields.length > 0) {
            alert("No values provided for some required fields");
            console.log("Missing values for fields:", missingFields);
            return;
        }

        const jsonOutput = JSON.stringify(values);
        console.log('Form Data JSON:', jsonOutput);

        try {
            const response = await createSurvey({
                variables: {
                    token,
                    input: {
                        name: 'Irish Life Health Survey',
                        HospitalId: patient?.hospitalId,
                        patientId: Number(patient?.id),
                        SurveyJSON: jsonOutput,
                        accesslevel: 'Admin',
                        ts_CreateDate: new Date().toISOString(),
                    }
                }
            });

            // Handle the response from a GraphQL mutation
            if (response.data) {
                console.log('Survey created successfully!');
                history.push('/survey-success'); // Navigate to the success page
            } else if (response.errors) {
                console.error('API request failed:', response.errors);
                alert('An error occurred while creating the survey');
            }
        } catch (error) {
            console.error('Error creating survey:', error);
            alert('An unexpected error occurred while submitting the form');
        }

    };
/*
Q.2 will be the first question and is just missing the note to be displayed prior to the satisfaction question
Please answer the rest of the survey keeping the patient experience in mind.
 
For Q.3 only the scale for statement A has been updated. It needs to be done for all statements under Q.3 pleases
*/


    return (
        <div className="container mt-5">
            {messageTokenLoading ? (
                <div>Loading...</div>
            ) : (
                !messageToken ? (
                    <div style={{color: "#007bff"}}>
                        {messageToken === 0 ? "You have already completed the survey - Thank you!" : "Loading..."}
                    </div>
                ) : (
                    <>
                        <Box sx={{px: 0}}>
                            <FormLabel id="Q0" sx={{
                                typography: 'body1',
                                fontWeight: 'bold',
                                mx: 1,
                                p: 2,
                                width: 'auto',
                                fontSize: 'h6.fontSize',
                                color: '#5c61ac'
                            }}>
                                Irish Life Patient Experience Feedback
                            </FormLabel>
                        </Box>
                        <Box sx={{px: 0}}>
                            <FormLabel id="Q0" sx={{
                                typography: 'body1',
                                fontWeight: 'normal',
                                mx: 1,
                                p: 2,
                                width: 'auto',
                                fontSize: 'h.fontSize',
                                color: 'black'
                            }}>
                                Dear {capitaliseFirstLetter((patient?.Patient_Forenames)?.trim() || '')}, <div>Thank you for your recent visit to the Irish Life Health ExpressCare Clinic. 
                                We greatly value your feedback and would appreciate it if you could take a few moments to complete a brief survey that should take no more than 3 minutes of your time.
                                Your responses will help us improve our services.
                                </div>
                                Thank you,
                                Irish Life Health ExpressCare.
                            </FormLabel>
                        </Box>
                        <Box sx={{px: 0}}>
                            <FormLabel id="Q0a" sx={{
                                typography: 'body1',
                                fontWeight: 'normal',
                                mx: 1,
                                p: 2,
                                width: 'auto',
                                fontSize: 'h.fontSize',
                                color: 'black',
                                fontStyle: 'italic'
                            }}>
                                Medical services, and specific questions regarding treatments or services, are the responsibility of Affidea Diagnostics 
                                Ireland Limited. Irish Life Health is not responsible for the provision of these medical services.
                            </FormLabel>
                        </Box>
                        <FormControl>
                            <DynamicRadioGroup
                                questionId="Q1"
                                questionText="Q1. Which of the following best describes the reason for your visit to the Irish Life Health ExpressCare Clinic?"
                                value={values.Q1}
                                onChange={handleRadioChange}
                                px={3}
                                numOptions={2}
                                lowerRatingDescription={'Visited to get treatment for myself'}
                                upperRatingDescription={'Visited to bring someone else for treatment'}
                            />
                             <Box sx={{px: 3}}>
                            <FormLabel sx={{color: '#5c61ac', fontWeight: 'bold',}}>Please answer the rest of the survey keeping the patient experience in mind.</FormLabel>
                            </Box>
                            <DynamicRadioGroup
                                questionId="Q2"
                                questionText="Q2. Overall, how would you rate your experience of using Irish Life Health ExpressCare Clinic?"
                                value={values.Q2}
                                onChange={handleRadioChange}
                                px={3}
                                numOptions={5}
                                lowerRatingDescription={'Extremely Dissatisfied'}
                                upperRatingDescription={'Extremely Satisfied'}
                            />
                            <Box sx={{px: 3}}>
                                <FormLabel id="Q3" sx={{typography: 'body1', fontWeight: 'bold'}}>
                                    Q3. Based on your experience of using Irish Life Health ExpressCare Clinic, how
                                    would
                                    you rate the following:
                                </FormLabel>
                            </Box>
                            <DynamicRadioGroup
                                questionId="Q3a"
                                questionText="a. Range of clinical services provided"
                                value={values.Q3a}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Extremely Dissatisfied'}
                                upperRatingDescription={'Extremely Satisfied'}
                            />
                            <DynamicRadioGroup
                                questionId="Q3b"
                                questionText="b. Professionalism & helpfulness of staff during your visit"
                                value={values.Q3b}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Extremely Dissatisfied'}
                                upperRatingDescription={'Extremely Satisfied'}
                            />
                            <DynamicRadioGroup
                                questionId="Q3c"
                                questionText="c. Ease of check-in & registration at the clinic"
                                value={values.Q3c}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Extremely Dissatisfied'}
                                upperRatingDescription={'Extremely Satisfied'}
                            />
                            <DynamicRadioGroup
                                questionId="Q3d"
                                questionText="d. Understanding your level of cover for your treatment"
                                value={values.Q3d}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Extremely Dissatisfied'}
                                upperRatingDescription={'Extremely Satisfied'}
                            />
                            <DynamicRadioGroup
                                questionId="Q3e"
                                questionText="e. Explanation provided for the diagnosis, treatment & next steps"
                                value={values.Q3e}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Extremely Dissatisfied'}
                                upperRatingDescription={'Extremely Satisfied'}
                            />
                            <DynamicRadioGroup
                                questionId="Q4"
                                questionText="Q4. How likely are you to recommend Irish Life Health ExpressCare Clinic to a friend or colleague?"
                                value={values.Q4}
                                onChange={handleRadioChange}
                                px={3}
                                numOptions={11}
                                lowerRatingDescription={'Extremely Unlikely'}
                                upperRatingDescription={'Extremely Likely'}
                                startIndex={0}
                            />
                            <Box sx={{px: 3}}>
                                <FormLabel id="Q5" sx={{typography: 'body1', fontWeight: 'bold'}}>
                                    Q5. How do you feel about Irish Life Health as an insurance provider following your
                                    visit to the Irish Life Health ExpressCare Clinic:
                                </FormLabel>
                            </Box>
                            <DynamicRadioGroup
                                questionId="Q5a"
                                questionText="a. Is a provider that looks after its customers"
                                value={values.Q5a}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Strongly Disagree'}
                                upperRatingDescription={'Strongly Agree'}
                            />
                            <DynamicRadioGroup
                                questionId="Q5b"
                                questionText="b. Provides services that help you get better faster"
                                value={values.Q5b}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Strongly Disagree'}
                                upperRatingDescription={'Strongly Agree'}
                            />
                            <DynamicRadioGroup
                                questionId="Q5c"
                                questionText="c. Is a provider that offers relevant health cover"
                                value={values.Q5c}
                                onChange={handleRadioChange}
                                px={8}
                                numOptions={5}
                                lowerRatingDescription={'Strongly Disagree'}
                                upperRatingDescription={'Strongly Agree'}
                            />
                            <DynamicTextFieldGroup
                                questionId="Q6"
                                questionText="Q6. We would welcome any comments you may have that can help us to improve our service"
                                value={values.Q6}
                                onChange={handleTextFieldChange}
                                px={0}
                                multiline={true}
                                rows={4}
                                placeholder="Please provide your feedback here..."
                            />
                            <Box sx={{mb: 2}}></Box>
                            <DynamicYesNoGroup
                                questionId="Q7"
                                questionText="Q7. Would you like the Clinic Manager to call you to follow up on any ISSUES OR CONCERNS that you have regarding your visit?"
                                value={values.Q7}
                                onChange={handleRadioChange}
                                px={3}
                                numOptions={2}
                            />
                            {Health_Insurance_Company !== 2 ? (
                            <DynamicRadioGroup
                                questionId="Q8"
                                questionText="Q8. How likely are you to consider taking out a health insurance policy with Irish Life Health after your ExpressCare Clinic visit?"
                                value={values.Q8}
                                onChange={handleRadioChange}
                                px={3}
                                numOptions={7}
                                lowerRatingDescription={'Extremely Unlikely'}
                                upperRatingDescription={'Extremely Likely'}
                            />) : null}
                            <Box sx={{alignSelf: 'center', mb: 1, borderBottom: '50px solid transparent'}}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => {
                                        handleSubmit().then(r => console.log(r));
                                    }}
                                >
                                    Submit Survey
                                </Button>
                            </Box>
                        </FormControl>
                    </>
                )
            )}
        </div>
    )
};
